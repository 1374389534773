import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WhiteButton from 'src/Elements/WhiteButton';
import { Container } from '@material-ui/core';
import WhiteTextField from 'src/Elements/WhiteTextField';
import { AuthContext } from 'src/AuthProvider';
import { NetworkProvider } from 'src/NetworkProvider';
import { createThumbnail } from 'src/Utilities/Utilities';
import ExerciseVideoThumbnail from '../Categories/PlanCalendar/Exercises/ExerciseVideoThumbnail';
import DeleteButton from 'src/Elements/Deletebutton';
import ImagePreview from 'src/Elements/ImagePreview';
import BlueButton from 'src/Elements/BlueButton';
import { MAX_DAILY_VIDEO_SIZE_MB, MAX_DAILY_VIDEO_DURATION_SECONDS } from 'src/contants';

const DailyVideo = ({ setLoadingText }) => {
    const classes = useStyles();
    const videoFileRef = useRef();
    const imageFileRef = useRef();
    const [error, setError] = useState('');
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const [dailyVideo, setDailyVideo] = useState(null);
    const [title, setTitle] = useState('');

    const [imageBlob, setImageBlob] = useState(null);
    const [imageFile, setImageFile] = useState(null);

    const [thumbnailBlob, setThumbnailBlob] = useState(null);
    const [videoFile, setVideoFile] = useState(null);

    useEffect(() => {
        fetchDailyVideo();
    }, []);

    const fetchDailyVideo = async () => {
        try {
            setLoadingText("Loading video...")
            const dailyVideo = await NetworkProvider.get_daily_video(loginCredentials, setLoginCredentials);
            setLoadingText(null)
            setDailyVideo(dailyVideo)
            setTitle(dailyVideo.title)
        } catch (error) {
            setLoadingText(null)
            setDailyVideo(null)
        }
    }

    const onDeleteDailyVideoClick = async () => {
        try {
            setLoadingText("Deleting video...")
            await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, "daily/" + dailyVideo.video);
            await NetworkProvider.delete_daily_video(loginCredentials, setLoginCredentials)
            setDailyVideo(null)
            setThumbnailBlob(null)
            setLoadingText(null)
            setTitle('')
        } catch (error) {
            setLoadingText(null)
            alert("Error deleting video: " + error);
        }
    }


    const handleVideoUpload = async (videoFile) => {

        // Check size
        const maxSizeBytes = MAX_DAILY_VIDEO_SIZE_MB * 1024 * 1024;
        if (videoFile.size > maxSizeBytes) {
            alert("The selected video exceeds the maximum size limit of " + MAX_DAILY_VIDEO_SIZE_MB + "MB");
            return;
        }

        // Check duration by loading metadata
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(videoFile);
        document.body.appendChild(video);
        const metadataLoaded = new Promise((resolve) => {
            video.onloadedmetadata = () => resolve();
        });
        await metadataLoaded;
        if (Math.ceil(video.duration) > MAX_DAILY_VIDEO_DURATION_SECONDS) {
            alert("The selected video exceeds the maximum duration limit of " + MAX_DAILY_VIDEO_DURATION_SECONDS + " seconds");
            return;
        }

        setVideoFile(videoFile)
        await createThumbnail(videoFile, "local", (thumbnailFile) => {
            setThumbnailBlob(URL.createObjectURL(thumbnailFile))
        });
    };

    const handleImageUpload = async (imageFile) => {
        setImageBlob(URL.createObjectURL(imageFile))
        setImageFile(imageFile)
    }

    const onUploadVideoClick = () => {
        videoFileRef.current.click();
    };

    const onUploadImageClick = () => {
        imageFileRef.current.click();
    };


    const handleTitleChange = (event) => {
        const newTitle = event.target.value;
        setTitle(newTitle)
    }

    const onVideoUploadProgress = (percentage) => {
        setLoadingText("Uploading video " + percentage +"%")
    }

    const onUpdateDailyVideoClick = async () => {

        let newImageUrl = null
        let newVideoUrl = null
        const newTitle = title

        // Upload new image
        if (imageFile != null) {
            setLoadingText("Deleting old image")
            const oldImage = dailyVideo?.image
            if (oldImage) {
                try {
                    await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, oldImage)
                } catch { }
            }

            try {
                setLoadingText("Uploading new image")
                newImageUrl = await NetworkProvider.upload_image(loginCredentials, setLoginCredentials, imageFile);
                if (videoFile == null) {
                    onCompleteUpdateDailyVideo(null, null, newTitle, newImageUrl);
                }
            } catch {
                setLoadingText(null)
            }
        }

        // Upload video
        if (videoFile != null) {
            try {
                setLoadingText("Uploading video...")
                newVideoUrl = await NetworkProvider.upload_daily_video(loginCredentials, setLoginCredentials, videoFile, onVideoUploadProgress);

                // Delete previous video
                const previousVideo = dailyVideo?.video
                if (previousVideo && previousVideo != "") {
                    try {
                        NetworkProvider.delete_video(loginCredentials, setLoginCredentials, previousVideo)
                    } catch { }
                }

                let videoName;
                const indexOfLastDot = newVideoUrl.lastIndexOf(".");
                if (indexOfLastDot === -1) {
                    videoName = newVideoUrl
                } else {
                    videoName = newVideoUrl.substring(0, indexOfLastDot);
                }
                await createThumbnail(videoFile, videoName, (thumbnailFile) => {
                    onCompleteUpdateDailyVideo(thumbnailFile, newVideoUrl, newTitle, newImageUrl);
                });
            } catch (error) {
                setLoadingText(null)
                alert("Error uploading video: " + error);
            }
        }

        if (videoFile == null && imageFile == null) {
            onCompleteUpdateDailyVideo(null, null, newTitle, null);
        }
    }

    const onCompleteUpdateDailyVideo = async (thumbnailFile, video, title, image) => {

        setLoadingText("Updating daily video")
        let dailyVideoUpdate = null
        if (dailyVideo == null) {
            dailyVideoUpdate = await NetworkProvider.create_daily_video(loginCredentials, setLoginCredentials, video, title, image)
        } else {
            await NetworkProvider.delete_video(loginCredentials, setLoginCredentials, "daily/" + dailyVideo.video);
            dailyVideoUpdate = await NetworkProvider.update_daily_video(loginCredentials, setLoginCredentials, video, title, image)
        }
        setDailyVideo(dailyVideoUpdate)
        setVideoFile(null)
        setImageBlob(null)
        setImageFile(null)
        setLoadingText(null)
    }

    const renderVideoThumbnail = () => {
        if (thumbnailBlob) {
            return (
                <div className={classes.thumbnailContainer}>
                    <ImagePreview
                        src={thumbnailBlob}
                        alt={"Preview image"}
                        className={classes.imagePreview}
                    />
                </div>
            );
        } else if (dailyVideo) {
            return (
                <div className={classes.thumbnailContainer}>
                    <ExerciseVideoThumbnail
                        key={dailyVideo.video}
                        authorId={loginCredentials.authorId}
                        fileName={dailyVideo.video}
                    />
                </div>
            );
        } else {
            return null;
        }
    };

    const renderButtonContainer = () => {
        const hasTitleChanged = dailyVideo ? title != dailyVideo?.title : false
        const hasChanges = imageFile || videoFile || hasTitleChanged;
        const buttonTitle = dailyVideo ? "Submit changes" : "Create";

        return (
            <div className={classes.deleteContainer}>
                {hasChanges && (
                    <div className={classes.containerButton}>
                        <BlueButton onClick={onUpdateDailyVideoClick}>{buttonTitle}</BlueButton>
                    </div>
                )}
                {dailyVideo && (
                    <div className={classes.containerButton}>
                        <DeleteButton onClick={onDeleteDailyVideoClick} />
                    </div>
                )}
            </div>
        );
    };

    const renderActiveString = () => {
        if (!dailyVideo || !dailyVideo.created_at) {
            return null;
        }
    
        const created_at = dailyVideo.created_at.endsWith('Z') ? dailyVideo.created_at : dailyVideo.created_at + 'Z';
        const createdAtDate = new Date(created_at);
        const currentDate = new Date();
        const createdAtTimeUTC = createdAtDate.getTime();
        const currentTimeUTC = currentDate.getTime();
        const differenceInMilliseconds = currentTimeUTC - createdAtTimeUTC;
        const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    
        if (differenceInHours < 24) {
            const hoursRemaining = 24 - differenceInHours;
            const roundedHoursRemaining = Math.ceil(hoursRemaining);
            return <h1 className={classes.title}>{`Video will be active for ${roundedHoursRemaining} ${roundedHoursRemaining === 1 ? 'hour' : 'hours'}`}</h1>;
        } else {
            return <h1 className={classes.title}>{"Video is inactive"}</h1>;
        }
    };

    return (
        <div className={classes.workoutPlanContent}>
            <div className={classes.workoutPlanContainer}>
                <h1 className={classes.title}>{'Daily video'}</h1>
                <p className={classes.subtitle}>Upload a daily greeting to your subscribers</p>
                <p className={classes.description}>
                    Add a title, video, and background image. <br />
                    Daily video stays active for 24 hours.<br />
                    If any changes are submitted the timer is reset.<br />
                    Video can be up to {Math.floor(MAX_DAILY_VIDEO_DURATION_SECONDS / 60)} minutes long and up to {MAX_DAILY_VIDEO_SIZE_MB}MB in size
                </p>
                <WhiteTextField
                    label="Enter video title"
                    variant="outlined"
                    className={classes.textField}
                    error={Boolean(error)}
                    helperText={error}
                    value={title}
                    onChange={handleTitleChange}
                />
                <Container className={classes.imageUploadContainer}>
                    {renderVideoThumbnail()}
                    <p className={classes.imageUploadTitle}>Daily video preview</p>
                    <div className={classes.uploadButtonContainer}>
                        <input
                            type="file"
                            accept="video/*"
                            onChange={(e) =>
                                handleVideoUpload(e.target.files[0])
                            }
                            ref={videoFileRef}
                            style={{ display: 'none' }}
                        />
                        <WhiteButton onClick={onUploadVideoClick}>Upload video</WhiteButton>
                    </div>
                </Container>
                <Container className={classes.imageUploadContainer}>
                    {((dailyVideo?.image) || imageBlob) && (
                        <div className={classes.thumbnailContainer}>
                            <ImagePreview
                                src={imageBlob ? imageBlob : dailyVideo.image}
                                alt={"daily video image"}
                                className={classes.imagePreview}
                            />
                        </div>
                    )}
                    <p className={classes.imageUploadTitle}>Daily video image preview</p>
                    <div className={classes.uploadButtonContainer}>
                        <input
                            type="file"
                            accept="image/*"
                            onChange={(e) =>
                                handleImageUpload(e.target.files[0])
                            }
                            ref={imageFileRef}
                            style={{ display: 'none' }}
                        />
                        <WhiteButton onClick={onUploadImageClick}>Upload image</WhiteButton>
                    </div>
                </Container>
                {renderActiveString()}
                {renderButtonContainer()}
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    workoutPlanContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingRight: 20,
    },
    workoutPlanContainer: {
        width: '70%',
    },
    title: {
        textAlign: 'left',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#16192C',
    },
    subtitle: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#425466',
    },
    description: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#737373',
        marginBottom: 30
    },
    textField: {
        width: '100%',
        marginBottom: 20,
    },
    imageUploadContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
    },
    uploadButtonContainer: {
        width: '100px'
    },
    continueButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%'
    },
    deleteContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    containerButton: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    imagePreview: {
        width: '120px',
        height: '80px',
        objectFit: 'cover'
    },
    thumbnailContainer: {
        borderRadius: '8px',
        overflow: 'hidden',
        width: '120px',
        height: '80px',
    }
}));

export default DailyVideo;
