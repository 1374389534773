import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';
import plusIcon from 'src/Assets/new/plusIcon.svg';


const useStyles = makeStyles(() => ({
    emptyDayContainer: {
        width: '130px',
        height: '109px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'white',
        overflow: 'hidden',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        margin: '3px'
    },
    plusIcon: {
        width: '16px',
        height: '16px'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '15px',
        boxSizing: 'border-box',
        width: '130px',
        minHeight: '109px',
        background: 'white',
        overflow: 'hidden',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        margin: '3px'
    },
    exerciseButton: {
        height: '36px',
    },
    exerciseButtonSpacer: {
        height: '5px'
    },
}));

const EmptyDayButton = ({ isAddingExercise, onIsAddingExerciseClick, onClick, onShouldAddExerciseClick }) => {
    const classes = useStyles();

    const BlueButton = styled(Button)({
        width: '100% !important',
        backgroundColor: '#3F6EE8 !important',
        color: 'white !important',
        padding: '10px 20px !important',
        borderRadius: '8px !important',
        cursor: 'pointer !important',
        fontSize: '9px !important',
        fontWeight: '600 !important',
        fontFamily: 'Inter !important',
        textTransform: 'none !important',
        '&:hover': {
            backgroundColor: '#305dcf !important',
        },
    });
    
    const BlueishButton = styled(Button)({
        width: '100% !important',
        backgroundColor: '#E4ECF7 !important',
        color: '#505780 !important',
        padding: '10px 20px !important',
        borderRadius: '8px !important',
        cursor: 'pointer !important',
        fontSize: '9px !important',
        fontWeight: '600 !important',
        fontFamily: 'Inter !important',
        textTransform: 'none !important',
        '&:hover': {
            backgroundColor: '#E4EFFF !important',
        },
    });

    return (
        <div>
            {isAddingExercise ? (
                <div className={classes.buttonContainer}>
                    <BlueButton
                        className={classes.exerciseButton}
                        onClick={onShouldAddExerciseClick}
                    >
                        Add Exercise
                    </BlueButton>
                    <div className={classes.exerciseButtonSpacer}></div>
                    <BlueishButton
                        className={classes.exerciseButton}
                        onClick={onClick}
                    >
                        Rest Day
                    </BlueishButton>
                </div>
            ) : (
                <div className={classes.emptyDayContainer} onClick={onIsAddingExerciseClick}>
                    <img
                        src={plusIcon}
                        alt={'plus icon'}
                        className={classes.plusIcon}
                    />
                </div>
            )}
        </div>
    );
};

export default EmptyDayButton