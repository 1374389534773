import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const BlueBorderButton = styled(Button)({
    width: '100% !important',
    backgroundColor: 'transparent !important',
    color: '#455DF1 !important',
    padding: '10px 20px !important',
    borderRadius: '8px !important',
    cursor: 'pointer !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    fontFamily: 'Inter !important',
    border: '1px dotted #127EEA !important',
    textTransform: 'none !important',
    '&:hover': {
        color: '#305dcf !important',
    },
});

  export default BlueBorderButton;