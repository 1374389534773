import { createContext } from 'react';

/*
 0 - Onboarding
 1 - Registration with CompanyDetailsForm
 2 - PurchaseSuccess
 3 - Admin panel
*/
const NavigationContext = createContext({
  onboardingState: 0,
  setOnboardingState: () => {},
});

export default NavigationContext;