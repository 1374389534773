import React, { useState, useEffect, useRef, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Backdrop from '@material-ui/core/Backdrop';
import BackButton from 'src/Elements/BackButton';

import WorkoutDayButton from './Buttons/WorkoutDayButton';
import EmptyDayButton from './Buttons/EmptyDayButton';
import AddWeekButton from './Buttons/AddWeekButton';
import Exercises from './Exercises/Exercises';
import { AuthContext } from 'src/AuthProvider';
import deleteWeek from "../../../../Assets/trash.svg"

import { MAX__WEEKS_IN_PLAN } from 'src/contants';
import { NetworkProvider } from 'src/NetworkProvider';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import DuplicateDeletePopover from './DuplicateDeletePopover';

const PlanCalendar = ({ plan, onPlanUpdated, onClose, setLoadingText }) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorWeekIndex, setAnchorWeekIndex] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const initialPlan = {
        weeks: [{
            index: 0,
            days: []
        }],
    };

    const initialWeek = {
        index: 0,
        days: [
            { index: 0, id: 0, name: '', image: '', exercises: [] },
            { index: 1, id: 1, name: '', image: '', exercises: [] },
            { index: 2, id: 2, name: '', image: '', exercises: [] },
            { index: 3, id: 4, name: '', image: '', exercises: [] },
            { index: 4, id: 5, name: '', image: '', exercises: [] },
            { index: 5, id: 6, name: '', image: '', exercises: [] },
            { index: 6, id: 7, name: '', image: '', exercises: [] }
        ]
    };

    const [localPlan, setLocalPlan] = useState(initialPlan);
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const [editingExerciseDay, setEditingExerciseDay] = useState({});
    const [openDay, setOpenDay] = useState([]);
    const isInitialMount = useRef(true);


    useEffect(() => {
        setLocalPlan(plan);
    }, [plan]);

    useEffect(() => {
        if (!isInitialMount.current) {
            onPlanUpdated(localPlan);
        } else {
            isInitialMount.current = false;
        }
    }, [localPlan]);


    const onEmptyDayClick = (day, week) => {
        if (!day && !week) {
            setEditingExerciseDay({ day: -1, week: -1 })
            return
        }
        setEditingExerciseDay({ day: day.index, week: week.index })
    }

    const onShouldAddExerciseClick = (day, week) => {
        const selectedWeek = localPlan.weeks.find(w => w.index === week.index);
        const selectedDay = selectedWeek.days.find(selectedWeekDay => selectedWeekDay.index === day.index);

        setEditingExerciseDay({ day: day.index, week: week.index })
        setOpenDay(selectedDay);
        setIsSideMenuOpen(true);
    }

    const addWeek = async () => {
        const newWeekIndex = CategoriesReducer.getMaxWeekIndex(localPlan.weeks);
        const newWeek = { days: initialWeek.days, index: newWeekIndex + 1 };

        try {
            const newWeekInResponse = await NetworkProvider.add_week(loginCredentials, setLoginCredentials, newWeek, plan.id);
            newWeekInResponse.days.sort((a, b) => a.index - b.index);
            setLocalPlan({ ...localPlan, weeks: [...localPlan.weeks, newWeekInResponse] });
        } catch (error) {
            window.alert(error);
        }
    };

    const onDayUpdated = (newDay) => {
        const updatedPlan = { ...localPlan };
        const { week: weekIndex, day: dayIndex } = editingExerciseDay;

        if (weekIndex >= 0 && weekIndex < updatedPlan.weeks.length &&
            dayIndex >= 0 && dayIndex < updatedPlan.weeks[weekIndex].days.length) {
            updatedPlan.weeks[weekIndex].days[dayIndex] = newDay;

            setLocalPlan(updatedPlan);
        }
    };

    const onDoneClick = async () => {
        setIsSideMenuOpen(false);
        setEditingExerciseDay({});

        try {
            const updatedWeeks = await NetworkProvider.update_weeks(loginCredentials, setLoginCredentials, localPlan);

            const updatedPlan = {
                ...localPlan,
                weeks: updatedWeeks
            };

            setLocalPlan(updatedPlan);
        } catch (error) {
            window.alert(error);
        }
    };

    const onBackClick = () => {
        setIsSideMenuOpen(false);
        onClose();
    }

    const onDeleteWeekClick = async () => {

        if (anchorWeekIndex == null) {
            window.alert("No week selected")
            return;
        }

        const weekIndex = anchorWeekIndex;
        const isConfirmed = window.confirm("Are you sure you want to delete week " + (weekIndex + 1) + " with all exercises?");

        if (!isConfirmed) {
            return;
        }

        const week = localPlan.weeks[weekIndex]

        try {
            setLoadingText("Deleting week")
            await CategoriesReducer.deleteWeek(loginCredentials, setLoginCredentials, week, localPlan);

            setLocalPlan(currentPlan => ({
                ...currentPlan,
                weeks: currentPlan.weeks.filter((_, index) => index !== weekIndex)
            }));

            setLoadingText(null)
        } catch (error) {
            window.alert(error);
        }
    }

    const onDuplicateWeekClick = async () => {
        if (anchorWeekIndex == null) {
            window.alert("No week selected")
            return;
        }

        const weekIndex = anchorWeekIndex;
        const isConfirmed = window.confirm("Are you sure you want to duplicate week " + (weekIndex + 1));

        if (!isConfirmed) {
            return;
        }

        const weekToCopy = localPlan.weeks[weekIndex];

        if (!weekToCopy) {
            window.alert("Week does not exist.");
            return;
        }

        const newWeekIndex = CategoriesReducer.getMaxWeekIndex(localPlan.weeks)
        const newWeek = { days: weekToCopy.days, index: newWeekIndex + 1 };
        const newWeekInResponse = await NetworkProvider.add_week(loginCredentials, setLoginCredentials, newWeek, localPlan.id);
        newWeekInResponse.days.sort((a, b) => a.index - b.index);
        try {
            setLoadingText("Duplicating week");
            setLocalPlan(currentPlan => ({
                ...currentPlan,
                weeks: [...currentPlan.weeks, newWeekInResponse]
            }));

            setLoadingText(null);
        } catch (error) {
            window.alert("Failed to duplicate week: " + error.message);
        }
    }

    const onThreeDotsClick = (event, weekIndex) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        setAnchorWeekIndex(weekIndex);
    }

    const handleClose = () => {
        setAnchorEl(null);
        setAnchorWeekIndex(null)
    };

    return (
        <div className="plan-calendar">
            <BackButton onClick={onBackClick} />
            <div className={classes.container}>
                {localPlan.weeks.length > 0 ? (
                    <table className={classes.table}>
                        <thead>
                            <tr>
                                <th></th>
                                {localPlan.weeks.map((week, weekIndex) => (
                                    <th key={`week-${weekIndex}`} className={classes.titleText} >
                                        <div className={classes.removeWeekButton}>
                                            <span>Week {weekIndex + 1}</span>
                                            <div className={classes.dots} onClick={(event) => {
                                                event.stopPropagation();
                                                onThreeDotsClick(event, weekIndex);
                                            }}>
                                                <span className={classes.dot}></span>
                                                <span className={classes.dot}></span>
                                                <span className={classes.dot}></span>
                                            </div>
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Array.from({ length: 7 }, (_, dayIndex) => (
                                <tr key={`day-row-${dayIndex}`}>
                                    <td className={classes.titleText}>Day {dayIndex + 1}</td>
                                    {localPlan.weeks.map((week, weekIndex) => {
                                        const day = week.days[dayIndex];
                                        const isAddingExercise = editingExerciseDay.day === day?.index && editingExerciseDay.week === week.index;

                                        if (day == null) {
                                            return <td key={`day-empty-${weekIndex}-${dayIndex}`} className={classes.td}>Empty</td>;
                                        }

                                        return (
                                            <td key={`day-${weekIndex}-${dayIndex}`} className={classes.td}>
                                                <div className="day-content">
                                                    {day.exercises && day.exercises.length > 0 ? (
                                                        <WorkoutDayButton
                                                            day={day}
                                                            authorId={loginCredentials.authorId}
                                                            onDayClick={() => onShouldAddExerciseClick(day, week)}
                                                        />
                                                    ) : (
                                                        <EmptyDayButton
                                                            isAddingExercise={isAddingExercise}
                                                            onIsAddingExerciseClick={() => onEmptyDayClick(day, week)}
                                                            onClick={() => onEmptyDayClick(null, null)}
                                                            onShouldAddExerciseClick={() => onShouldAddExerciseClick(day, week)}
                                                        />
                                                    )}
                                                </div>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>) : null}
                {localPlan.weeks.length < MAX__WEEKS_IN_PLAN && (
                    <div className={classes.addWeekContainer}>
                        <AddWeekButton className={classes.addWeekButton} onClick={addWeek}>
                            Add Week
                        </AddWeekButton>
                    </div>
                )}

                <Drawer
                    className={classes.drawer}
                    variant="temporary"
                    anchor="right"
                    open={isSideMenuOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    onClose={onDoneClick}
                >
                    <Exercises
                        day={openDay}
                        plan={localPlan}
                        planId={localPlan.id}
                        onDayUpdated={onDayUpdated}
                        onDoneClick={onDoneClick}
                        setLoadingText={setLoadingText}
                    />
                </Drawer>
                <Backdrop
                    className={classes.backdrop}
                    open={isSideMenuOpen}
                    onClick={() => setIsSideMenuOpen(false)}
                />
            </div>
            <DuplicateDeletePopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                onDuplicateClick={() => onDuplicateWeekClick()}
                onDeleteClick={() => onDeleteWeekClick()}
            />
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    titleText: {
        color: '#6B6B6B',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        whiteSpace: 'nowrap'
    },
    container: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    table: {
        marginLeft: '40px',
        flex: 0,
        borderCollapse: 'collapse'
    },
    td: {
        padding: 3
    },
    addWeekContainer: {
        marginLeft: '16px',
        paddingTop: '5px',
    },
    addWeekButton: {
        width: '130px',
        height: '36px',
        whiteSpace: 'nowrap'
    },
    removeWeekButton: {
        color: '#6B6B6B',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        background: 'transparent',
        border: 'none'
    },
    removeIcon: {
        paddingLeft: 10
    },
    drawer: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        flexShrink: 0,
    },
    drawerPaper: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        backgroundColor: '#E4E9ED'
    },
    backdrop: {
        zIndex: 1000,
        color: '#fff',
    },
    dots: {
        cursor: 'pointer',
        display: 'flex', // Use flex to facilitate the vertical layout
        flexDirection: 'column', // Stack children vertically
        padding: '0 7px',
        height: '14px', // Set a fixed height if needed
        justifyContent: 'space-between', // Distribute the dots evenly vertically
    },
    dot: {
        height: '3px', // Example height for a single dot
        width: '3px', // Example width for a single dot
        backgroundColor: '#6B6B6B',
        borderRadius: '50%', // Make the dot circular
    }
}));

export default PlanCalendar;
