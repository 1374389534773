import { useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from 'src/AuthProvider';

import { SIGN_IN_URI, RESET_PASSWORD_URI, ADMIN_URI,  } from 'src/contants';

function ProtectedRoute({ children }) {
  const { loginCredentials } = useContext(AuthContext);
  const location = useLocation();
  const sourcePath = location.pathname;

  if ((!loginCredentials.email || !loginCredentials.password) && sourcePath == RESET_PASSWORD_URI) {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const user_id = queryParams.get('u');
    if (token && user_id) {
      return children
    } else {
      return <Navigate to={SIGN_IN_URI} />; 
    }
  }

  if ((!loginCredentials.email || !loginCredentials.password) && (sourcePath == ADMIN_URI)) {
    return <Navigate to={SIGN_IN_URI} />;
  }

  return children
}

export default ProtectedRoute;