import React, { useState, useEffect } from 'react';
import { video_thumbnail_url_from_file_name } from 'src/Utilities/Utilities';
import CircularProgress from '@mui/material/CircularProgress';

const ExerciseVideoThumbnail = ({ authorId, fileName }) => {
    const [imageUrl, setImageUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [retryCount, setRetryCount] = useState(0);

    useEffect(() => {
        if (fileName) {
            setLoading(true);
            const url = video_thumbnail_url_from_file_name(fileName, authorId);
            setImageUrl(url);
        }
    }, [fileName, retryCount, authorId]);

    const handleImageError = () => {
        if (!imageUrl || imageUrl.length == 0) {
            return;
        }
        console.log(`Error loading image. Retry attempt: ${retryCount + 1}`);
        setImageUrl(null)
        if (retryCount < 3) {
            setTimeout(() => {
                setRetryCount(prevRetryCount => prevRetryCount + 1);
                const url = video_thumbnail_url_from_file_name(fileName, authorId);
                setImageUrl(url);
            }, 5000);
        } else {
            setLoading(false);
        }
    };

    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <img
                src={imageUrl}
                alt="Exercise"
                style={{ width: '100%', height: '100%', objectFit: 'cover', display: 'block' }}
                onLoad={handleImageLoad}
                onError={handleImageError}
            />
            {loading && (
                <div style={{
                    position: 'absolute', top: 0, left: 0, right: 0, bottom: 0,
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 1)' 
                }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default ExerciseVideoThumbnail;
