import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import '../SignIn/SignIn.css'
import { useLocation } from 'react-router-dom';

import { useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';

import { RESET_PASSWORD_TITLE, RESET_PASSWORD_SUBTITLE, SIGN_IN_URI } from 'src/contants';


function ResetPassword() {

    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const authToken = queryParams.get('token');
    const user_id = queryParams.get('u');
    const authorId = queryParams.get('a');
    const isAuthor = authorId === null;


    async function handleResetPassword(formData, captchaToken) {

        if (captchaToken == null) {
            alert("Failed to validate captcha")
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken)
        } catch (error) {
            alert("Failed to validate captcha ", error)
            return
        }

        setLoadingText("Resetting password")

        try {
            const { password } = formData;

            if (isAuthor) {
                await NetworkProvider.update_author_password(authToken, user_id, password)
            } else {
                await NetworkProvider.update_user_password(authToken, user_id, password)
            }

            setLoadingText(null)
            if (isAuthor) {
                window.alert("Password has been reset. You will be redirected to sign in");
            } else {
                window.alert("Password has been reset. Log in the app using the new password");
            }
            navigate(SIGN_IN_URI);
        } catch (error) {
            setLoadingText(null)
            alert("Failed to update password, are you sure this email is registered in our system? ")
        }
    }


    function handleSignIn() {
        navigate(SIGN_IN_URI);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert("Failed to validate captcha" + error)
    }

    return (
        <div className="split-screen-container">
            <Helmet>
                <script type="application/ld+json">
                    {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Onboarding",
                    "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
                    "url": "${window.location.href}"
                }
            `}
                </script>
            </Helmet>
            <div className="left-section">
                <div className="labels-container">
                    <div className="textContainer">
                        <label className="title-label">{RESET_PASSWORD_TITLE}</label>
                        <label className="subtitle-label">{RESET_PASSWORD_SUBTITLE}</label>
                    </div>
                </div>
            </div>
            <div className="right-section">
                <div className="title-text">Enter new password</div>
                <UserTextFields
                    onboardingState={OnboardingState.RESET_PASSWORD}
                    onSubmit={handleResetPassword}
                    onValidatingCaptcha={() => setLoadingText("Validating captcha")}
                    onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                />
                <OnboardingPrompt
                    onClick={handleSignIn}
                    onboardingState={OnboardingState.SIGN_IN}
                />
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
}

export default ResetPassword;