import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExerciseVideoThumbnail from '../Exercises/ExerciseVideoThumbnail';

const useStyles = makeStyles(() => ({
    workoutDayContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '4px',
        boxSizing: 'border-box',
        width: '130px',
        minHeight: '109px',
        background: 'white',
        overflow: 'hidden',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
        borderRadius: '16px',
        margin: '3px',
        cursor: 'pointer'
    },
    workoutDayImage: {
        maxWidth: '100%',
        maxHeight: '60px',
        borderRadius: '16px'
    },
    workoutContainerExercises: {
        color: '#425466',
        textAlign: 'left',
        paddingBottom: '4px',
        paddingLeft: '5px',
        textAlign: 'left',
    },
    workoutContainerListItem: {
        margin: '0px 0',
        fontSize: 9,
        fontFamily: 'Inter',
        fontWeight: 400,
        textAlign: 'left',
    },
    workoutNameText: {
        color: '#27272E',
        fontSize: 10,
        fontFamily: 'Inter',
        fontWeight: 500,
        paddingTop: '5px',
    },
    thumbnailContainer: {
        width: '100%',
        height: 62,
        borderRadius: '16px',
        overflow: 'hidden',
    },
}));

const WorkoutDayButton = ({ day, onDayClick, authorId }) => {
    const classes = useStyles();

    const videoFileName = day.exercises[0].video
    const thumbnailKey = day.exercises[0].id

    return (
        <div className={classes.workoutDayContainer} onClick={onDayClick}>
            {videoFileName && (<div className={classes.thumbnailContainer}>
                <ExerciseVideoThumbnail
                    key={thumbnailKey}
                    fileName={videoFileName}
                    authorId={authorId}
                />
            </div>
            )}
            <div className={classes.workoutNameText}>{day.name}</div>
            <div className={classes.workoutContainerExercises}>
                {day.exercises.map((exercise) => (
                    <div className={classes.workoutContainerListItem} key={exercise.id}>
                        &middot; {exercise.name === 'Rest'
                            ? `${exercise.name} ${exercise.amount} (minutes)`
                            : exercise.name}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WorkoutDayButton;
