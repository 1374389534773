import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CategoryPopover from 'src/Components/Admin/Categories/EditDeletePopover/EditDeletePopover';

const EditableFoodCategory = ({ category, recipes, onCategoryClick, onDeleteClick, onEditClick, listeners }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const onThreeDotsClick = (event) => {
    event.stopPropagation(); 
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div key={category.id} className={classes.categoryBox} onClick={() => onCategoryClick(category)}>
        <h2 className={classes.categoryTitle}>{category.title}</h2>
        {recipes && <p className={classes.categorySubtitle}>{`${recipes.length} ${recipes.length === 1 ? 'recipe' : 'recipes'}`}</p>}
        <div className={classes.dots} onClick={onThreeDotsClick}>
          ...
        </div>
        <div {...listeners} className={classes.dragHandle}>☰</div>
      </div>
      <CategoryPopover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onEditClick={() => onEditClick(category)}
        onDeleteClick={() => onDeleteClick(category)}
      />
    </div>
  );
};

const useStyles = makeStyles({
  categoryBox: {
    position: 'relative',
    width: 166,
    height: 166,
    border: '1px solid #ACACAC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    boxSizing: 'border-box',
    cursor: 'pointer',
    backgroundColor: '#F8FAFC'
  },
  categoryTitle: {
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 600,
    marginBottom: 0,
    color: '#000000'
  },
  categorySubtitle: {
    marginBottom: 0,
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#737373',
  },
  dots: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    cursor: 'pointer',
    writingMode: 'vertical-lr'
  },
  dragHandle: {
    cursor: 'grab',
    position: 'absolute',
    bottom: 10,
    left: 10,
  },
});

export default EditableFoodCategory;
