import axios from 'axios';
import { API_URL } from "./contants";
import { SentryEventProvider } from './SentryEventProvider';

export class NetworkProvider {


  static throwable_error(error) {
    if (error.response && error.response.data && error.response.data.error) {
      const errorMessage = error.response.data.error;
      return errorMessage;
    } else if (error.message) {
      return error.message
    } else {
      return "An error occurred.";
    }
  }

  static async refreshedLoginCredentials(loginCredentials, setLoginCredentials) {
    const { email, password } = loginCredentials;

    const tokenData = await this.acquire_token(email, password);

    const { access_token, author_id } = tokenData;
    setLoginCredentials(access_token, author_id, email, password)

    const newLoginCredentials = {
      email,
      password,
      authorId: author_id,
      authToken: access_token
    }

    return newLoginCredentials
  }

  // Acquire bearer token
  static async acquire_token(mail, password) {
    const params = {
      mail,
      password
    };

    try {
      const response = await axios.post(`${API_URL}/token`, params);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('No such user exists');
      }
    }
  }


  // Get author display info
  static async get_author(loginCredentials, setLoginCredentials, retry = true) {

    try {
      const response = await axios.get(`${API_URL}/author/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {

        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return await this.get_author(newLoginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('No such user exists');
      }
    }
  }


  // Add author
  static insert_author(name, surname, mail, password) {
    const params = {
      name: name + " " + surname,
      mail,
      password
    };

    return axios.post(`${API_URL}/register-author/`, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          throw error.response.data;
        } else {
          throw new Error('An error occurred');
        }
      });
  }

  static async create_customer(loginCredentials, setLoginCredentials, coupon, payment_method_id, price_id, billingDetails, retry = true) {

    const params = {
      ...(billingDetails && { billingDetails }),
      ...(coupon && { coupon }),
      payment_method_id,
      price_id
    };

    try {
      const response = await axios.put(`${API_URL}/payment/${loginCredentials.authorId}`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.create_customer(loginCredentials, setLoginCredentials, coupon, payment_method_id, price_id, billingDetails, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data.detail;
      } else {
        throw new Error('An error occurred');
      }
    }
  }

  static async get_categories(loginCredentials, setLoginCredentials, retry = true) {

    try {
      const response = await axios.get(`${API_URL}/categories/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_categories(loginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to return categories');
      }
    }
  }

  static async add_category(loginCredentials, setLoginCredentials, name, retry = true) {
    try {
      const response = await axios.put(`${API_URL}/categories`, { name }, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_category(loginCredentials, setLoginCredentials, name, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to add category');
      }
    }
  }

  static async update_category(loginCredentials, setLoginCredentials, categoryId, name, retry = true) {
    try {
      const response = await axios.put(`${API_URL}/categories/${categoryId}`, { name }, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_category(loginCredentials, setLoginCredentials, categoryId, name, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update category');
      }
    }
  }


  static async delete_category(loginCredentials, setLoginCredentials, categoryId, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/categories/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_category(loginCredentials, setLoginCredentials, categoryId, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to delete category');
      }
    }
  }


  static async add_plan(loginCredentials, setLoginCredentials,
    categoryId,
    name,
    description,
    type,
    difficulty,
    duration,
    image,
    retry = true
  ) {
    const params = {
      name: name,
      image: image == null ? '' : image,
      level: "normal",
      progress: 0,
      categoryId,
      description: description,
      type,
      difficulty,
      duration,
      weeks: [],
      weeksId: 1
    };

    try {
      const response = await axios.post(`${API_URL}/plans`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_plan(loginCredentials, setLoginCredentials, categoryId, name, image, description, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to add a plan');
      }
    }
  }

  static async update_plan(loginCredentials, setLoginCredentials, plan, retry = true) {
    try {
      const response = await axios.put(`${API_URL}/plans/${plan.id}`, plan, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_plan(loginCredentials, setLoginCredentials, plan, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update a plan');
      }
    }
  }


  static async update_weeks(loginCredentials, setLoginCredentials, plan, retry = true) {
    try {
      const response = await axios.put(`${API_URL}/plans/${plan.id}/weeks/`, plan, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_weeks(loginCredentials, setLoginCredentials, plan, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update the plan weeks');
      }
    }
  }

  // Add new week to existing plan
  static async add_week(loginCredentials, setLoginCredentials, week, planId, retry = true) {
    try {
      const response = await axios.post(`${API_URL}/weeks/${planId}`, week, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_week(loginCredentials, setLoginCredentials, week, planId, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to add a plan');
      }
    }
  }

  static async delete_week(loginCredentials, setLoginCredentials, weekId, planId, retry = true) {

    try {
      const response = await axios.delete(`${API_URL}/weeks/${weekId}/plans/${planId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_week(loginCredentials, setLoginCredentials, weekId, planId, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to remove a week');
      }
    }
  }

  static async add_exercise(loginCredentials, setLoginCredentials, exercise, week_id, plan_id, day_id, retry = true) {
    try {
      const response = await axios.post(`${API_URL}/exercises?day_id=${day_id}&week_id=${week_id}&plan_id=${plan_id}`, exercise, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_exercise(loginCredentials, setLoginCredentials, exercise, week_id, plan_id, day_id, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to add an exercise');
      }
    }
  }

  static async delete_exercise(loginCredentials, setLoginCredentials, exercise_id, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/exercises/${exercise_id}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_exercise(loginCredentials, setLoginCredentials, exercise_id, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to delete an exercise');
      }
    }
  }


  static async delete_plan(loginCredentials, setLoginCredentials, plan_id, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/plans/${plan_id}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_plan(loginCredentials, setLoginCredentials, plan_id, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to delete a plan');
      }
    }
  }


  static delete_client(token, clientId, email, customerId) {
    const params = {
      email,
      clientId,
      token,
      customerId
    };

    return axios.delete(`${API_URL}/api/client`, { params })
      .then(response => {
        return response.data;
      })
      .catch(error => {
        throw this.throwable_error(error)
      });
  }

  // MARK: Payments
  static async get_payment_methods(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/payment/methods/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_payment_methods(loginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to fetch payment methods');
      }
    }
  }


  static async add_payment_method(loginCredentials, setLoginCredentials, payment_method_id, retry = true) {
    try {
      const response = await axios.post(
        `${API_URL}/payment/methods/${loginCredentials.authorId}?payment_method_id=${encodeURIComponent(payment_method_id)}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${loginCredentials.authToken}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_payment_method(loginCredentials, setLoginCredentials, payment_method_id, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to add payment method');
      }
    }
  }

  static async delete_payment_method(loginCredentials, setLoginCredentials, payment_method_id, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/payment/methods/${loginCredentials.authorId}?payment_method_id=${encodeURIComponent(payment_method_id)}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_payment_method(loginCredentials, setLoginCredentials, payment_method_id, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to remove payment method');
      }
    }
  }


  static async set_default_payment_method(loginCredentials, setLoginCredentials, payment_method_id, retry = true) {
    try {
      const response = await axios.put(
        `${API_URL}/payment/methods/${loginCredentials.authorId}/default?payment_method_id=${encodeURIComponent(payment_method_id)}`,
        null,
        {
          headers: {
            Authorization: `Bearer ${loginCredentials.authToken}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.set_default_payment_method(loginCredentials, setLoginCredentials, payment_method_id, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to set default payment method');
      }
    }
  }

  static async get_customer_info(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/payment/customer/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_customer_info(loginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to get customer info');
      }
    }
  }

  static async list_subscriptions(loginCredentials, setLoginCredentials, customerId, retry = true) {
    const params = { customer_id: customerId };
    try {
      const response = await axios.get(`${API_URL}/api/payments/subscriptions`, { params });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.list_subscriptions(loginCredentials, setLoginCredentials, customerId, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static async list_invoices(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/payment/invoices/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.list_invoices(loginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to get customer info');
      }
    }
  }

  static async update_billing_details(loginCredentials, setLoginCredentials, billing_details, retry = true) {
    try {
      const response = await axios.put(`${API_URL}/payment/customer/${loginCredentials.authorId}`, billing_details, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_billing_details(loginCredentials, setLoginCredentials, billing_details, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update billing info');
      }
    }
  }

  static async fetch_billing_details(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/payment/customer/${loginCredentials.authorId}/billing`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.fetch_billing_details(loginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to get customer info');
      }
    }
  }

  static async fetch_subscription_info(price_id) {
    try {
      const response = await axios.get(`${API_URL}/payment/subscription/info/${price_id}`, null);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static cancelSubscription(token, email, subscription_id) {
    const params = {
      token,
      email,
      subscription_id
    };

    return axios.post(`${API_URL}/api/payments/cancel-subscription`, params)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (error.response && error.response.status === 400) {
          throw new Error(error.response.data.error);
        } else {
          throw this.throwable_error(error);
        }
      });
  }

  static async validateCaptchaToken(token) {

    const params = { token };
    try {
      const response = await axios.post(`${API_URL}/validate_recaptcha`, params);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static async upload_video(loginCredentials, setLoginCredentials, file, onProgress, retry = true) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Authorization': `Bearer ${loginCredentials.authToken}`,
      'Content-Type': 'multipart/form-data'
    };

    const config = {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);  // Callback function to update progress in the UI
      }
    };

    try {
      const response = await axios.put(`${API_URL}/video`, formData, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.upload_video(loginCredentials, setLoginCredentials, file, onProgress, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static async upload_daily_video(loginCredentials, setLoginCredentials, file, onProgress, retry = true) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Authorization': `Bearer ${loginCredentials.authToken}`,
      'Content-Type': 'multipart/form-data'
    };

    const config = {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted);
      }
    };

    try {
      const response = await axios.put(`${API_URL}/video/daily`, formData, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.upload_daily_video(loginCredentials, setLoginCredentials, file, onProgress, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }


  static async upload_greeting_video(loginCredentials, setLoginCredentials, file, onProgress, retry = true) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      'Authorization': `Bearer ${loginCredentials.authToken}`,
      'Content-Type': 'multipart/form-data'
    };

    const config = {
      headers: headers,
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        onProgress(percentCompleted); // Call the onProgress callback with the current upload percentage
      }
    };

    try {
      const response = await axios.put(`${API_URL}/video/greeting`, formData, config);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        // If token expired, refresh login credentials and retry
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.upload_greeting_video(loginCredentials, setLoginCredentials, file, onProgress, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error); // Handle 400 Bad Request errors
      } else {
        throw this.throwable_error(error); // Handle other errors
      }
    }
  }


  static async delete_video(loginCredentials, setLoginCredentials, fileName, retry = true) {
    const headers = {
      'Authorization': `Bearer ${loginCredentials.authToken}`
    };

    try {
      const response = await axios.delete(`${API_URL}/video?file=${encodeURIComponent(fileName)}`, {
        headers: headers
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_video(loginCredentials, setLoginCredentials, fileName, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static async upload_image(loginCredentials, setLoginCredentials, file, retry = true) {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const headers = {
        'Authorization': `Bearer ${loginCredentials.authToken}`,
        'Content-Type': 'multipart/form-data'
      };

      const response = await axios.put(`${API_URL}/image`, formData, { headers });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.upload_image(loginCredentials, setLoginCredentials, file, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static async delete_image(loginCredentials, setLoginCredentials, image_url, retry = true) {
    try {
      const headers = {
        'Authorization': `Bearer ${loginCredentials.authToken}`
      };

      const response = await axios.delete(`${API_URL}/image`, {
        headers: headers,
        params: { image_url: image_url }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_image(loginCredentials, setLoginCredentials, image_url, false);
      } else if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }


  // MARK: Recipes

  static async get_recipes(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/recipes/author/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_recipes(loginCredentials, setLoginCredentials, false);
      } if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to fetch recipes');
      }
    }
  }

  static async add_food_category(loginCredentials, setLoginCredentials, title, index, retry = true) {
    const params = {title, index, authorId: loginCredentials.authorId}
    try {
      const response = await axios.post(`${API_URL}/foodcategories`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_food_category(newLoginCredentials, setLoginCredentials, title, index, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to add category');
      }
    }
  }

  static async update_food_category(loginCredentials, setLoginCredentials, title, id, index, retry = true) {
    const params = {title, id, index, authorId: loginCredentials.authorId}

    try {
      const response = await axios.put(`${API_URL}/foodcategories`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_food_category(newLoginCredentials, setLoginCredentials, title, id, index, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      }
    }
  }

  static async get_food_categories(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/foodcategories/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_food_categories(loginCredentials, setLoginCredentials, false);
      } if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to fetch food categories');
      }
    }
  }

  static async delete_food_category(loginCredentials, setLoginCredentials, foodcategoryId, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/foodcategories/${foodcategoryId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_food_category(newLoginCredentials, setLoginCredentials, foodcategoryId, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      }
    }
  }

  static async add_recipe(loginCredentials, setLoginCredentials, recipe, retry = true) {

    try {
      const response = await axios.post(`${API_URL}/recipes/`, recipe, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {

      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.add_recipe(newLoginCredentials, setLoginCredentials, recipe, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        SentryEventProvider.sendFailedAddRecipe(recipe, loginCredentials)
        throw new Error('Failed to add recipe');
      }
    }
  }

  static async update_recipe(loginCredentials, setLoginCredentials, recipe, retry = true) {

    try {
      const response = await axios.put(`${API_URL}/recipes/${recipe.id}`, recipe, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });
      return response.data;
    } catch (error) {

      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_recipe(newLoginCredentials, setLoginCredentials, recipe, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        SentryEventProvider.sendFailedAddRecipe(recipe, loginCredentials)
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async delete_recipe(loginCredentials, setLoginCredentials, recipe, retry = true) {

    try {
      const response = await axios.delete(`${API_URL}/recipes/${recipe.id}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_recipe(newLoginCredentials, setLoginCredentials, recipe, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        SentryEventProvider.sendFailedDeleteRecipe(recipe, loginCredentials)
        throw new Error('Failed to delete recipe');
      }
    }
  }

  static async send_password_reset_link(email) {
    const params = {
      email: email.toLowerCase(),
    };
    try {
      const response = await axios.post(`${API_URL}/email/forgot`, params);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error(error.response.data.error);
      } else {
        throw this.throwable_error(error);
      }
    }
  }

  static async update_user_password(authToken, user_id, password) {
    const params = { password };

    try {
      const response = await axios.put(`${API_URL}/users/${user_id}`, params, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async update_author_password(authToken, author_id, password) {
    const params = { password };

    try {
      const response = await axios.put(`${API_URL}/author/${author_id}`, params, {
        headers: {
          Authorization: `Bearer ${authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async create_daily_video(loginCredentials, setLoginCredentials, video, title, image, retry = true) {
    let params = {
      authorId: loginCredentials.authorId
    };

    video !== null && (params.video = video);
    title !== null && (params.title = title);
    image !== null && (params.image = image);

    try {
      const response = await axios.post(`${API_URL}/daily/`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.create_daily_video(newLoginCredentials, setLoginCredentials, video, title, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async update_daily_video(loginCredentials, setLoginCredentials, video, title, image, retry = true) {
    const params = {
      video,
      title,
      image
    };

    try {
      const response = await axios.put(`${API_URL}/daily/${loginCredentials.authorId}`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_daily_video(newLoginCredentials, setLoginCredentials, video, title, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async get_daily_video(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/daily/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_daily_video(newLoginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async delete_daily_video(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/daily/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_daily_video(newLoginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update recipe');
      }
    }
  }

  static async create_greeting_video(loginCredentials, setLoginCredentials, video, retry = true) {
    let params = {
      authorId: loginCredentials.authorId
    };

    video !== null && (params.video = video);

    try {
      const response = await axios.post(`${API_URL}/greeting/`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.create_greeting_video(newLoginCredentials, setLoginCredentials, video, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to create greeting video');
      }
    }
  }

  static async update_greeting_video(loginCredentials, setLoginCredentials, video, retry = true) {
    const params = { video };

    try {
      const response = await axios.put(`${API_URL}/greeting/${loginCredentials.authorId}`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.update_greeting_video(newLoginCredentials, setLoginCredentials, video, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to update greeting video');
      }
    }
  }

  static async get_greeting_video(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/greeting/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_greeting_video(newLoginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to get greeting video');
      }
    }
  }

  static async delete_greeting_video(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.delete(`${API_URL}/greeting/${loginCredentials.authorId}`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.delete_greeting_video(newLoginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to delete greeting video');
      }
    }
  }

  static async get_push_token_count(loginCredentials, setLoginCredentials, retry = true) {
    try {
      const response = await axios.get(`${API_URL}/author/notification/tokens`, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.get_push_token_count(newLoginCredentials, setLoginCredentials, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        throw new Error('Failed to fetch token count');
      }
    }
  }

  static async send_notification(loginCredentials, setLoginCredentials, title, body, retry = true) {
    const params = {
      title,
      body,
      author_id: parseInt(loginCredentials.authorId)
    };

    try {
      const response = await axios.post(`${API_URL}/author/notification`, params, {
        headers: {
          Authorization: `Bearer ${loginCredentials.authToken}`
        }
      });

      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401 && retry) {
        const newLoginCredentials = await this.refreshedLoginCredentials(loginCredentials, setLoginCredentials);
        return this.send_notification(newLoginCredentials, setLoginCredentials, title, body, false);
      } else if (error.response && error.response.status === 400) {
        throw error.response.data;
      } else {
        SentryEventProvider.sendFailedPushNotification(error, loginCredentials)
        throw new Error(error);
      }
    }
  }

}