import React, { useState, useEffect, useContext } from "react";
import { Helmet } from 'react-helmet';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CompanyDetailsForm from "./CompanyDetailsForm/CompanyDetailsForm";
import { useLocation, useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import { STRIPE_PK_KEY, PURCHASE_SUCCESS_URI, SUBSCRIPTION_ID } from 'src/contants';
import Popup from 'src/Components/Popup/Popup';
import { AuthContext } from 'src/AuthProvider';

import subscriptionCardLogo from "../../../Assets/subscriptionCardLogo.svg";

import './CompanySetup.css'

const stripePromise = loadStripe(STRIPE_PK_KEY, { locale: 'en' });

const CompanySetup = () => {

    const location = useLocation();
    const name = location.state?.name;
    const surname = location.state?.surname;
    const email = location.state?.email;

    const navigate = useNavigate();
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const [subscriptionInfo, setSubscriptionInfo] = useState(null);
    const [isRegistering, setIsRegistering] = useState(false);

    useEffect(() => {
        fetchSubscriptionInfo()
    }, []);

    const fetchSubscriptionInfo = async () => {
        const info = await NetworkProvider.fetch_subscription_info(SUBSCRIPTION_ID)
        setSubscriptionInfo(info)
    }

    const handleCompanyDetails = async (details) => {
        setIsRegistering(true);
        const paymentMethodId = details.paymentMethodId;
        const companyDetails = details.companyDetails;
        const coupon = companyDetails.coupon;

        try {
            // Create customer Id
            const customer = await NetworkProvider.create_customer(loginCredentials, setLoginCredentials, coupon, paymentMethodId, SUBSCRIPTION_ID, companyDetails);

            // Add first payment method
            await NetworkProvider.add_payment_method(loginCredentials, setLoginCredentials, paymentMethodId);

            // Make the payment method default
            await NetworkProvider.set_default_payment_method(loginCredentials, setLoginCredentials, paymentMethodId);

            return customer
        } catch (error) {
            setIsRegistering(false);
            window.alert(error);
        }
    };

    const onComplete = () => {
        navigate(PURCHASE_SUCCESS_URI);
    }

    const SubscriptionInfo = (subscriptionInfo) => {
        return (
            <div className="subscription-info">
                <div className="subscription-container">
                    <div className="subscription-card-information">
                        <h2>Subscription plan</h2>
                        <div className="susbscription-card-logo">
                            <img src={subscriptionCardLogo} alt="Done Step image" />
                        </div>
                    </div>
                    <div className="information-container-subscribtion" >
                        <div className="subscription-name">{subscriptionInfo.name}
                            <div className="subscription-price">{subscriptionInfo.price} Eur</div>
                        </div>
                        <div className="step-divider">
                            <hr></hr>
                        </div>
                        <div className="subscription-total-price"><b>Total Price: {subscriptionInfo.price} Eur/Monthly</b></div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="container">
            <div className="header-container">
                <Helmet>
                    <script type="application/ld+json">
                        {`
                        {
                            "@context": "https://schema.org",
                            "@type": "WebPage",
                            "name": "Sign Up Page",
                            "description": "Company signup page to subscribe to thinkitivelab fitness app",
                            "url": "${window.location.href}",
                            "about": {
                                "@type": "Organization",
                                "name": "Thinkitive Lab",
                                "description": "A company that provides IT services.",
                                "address": {
                                    "@type": "PostalAddress",
                                    "streetAddress": "Beberbeķu iela 46",
                                    "addressLocality": "Rīga",
                                    "addressRegion": "Vidzeme",
                                    "postalCode": "LV-1006",
                                    "addressCountry": "Latvia"
                                },
                                "vatID": "LV40203159237"
                            }
                        }
                    `}
                    </script>
                </Helmet>
                <div className="header">
                    <div className="step-container">
                        <div className="step-one">
                            Start your fitness app! 🥳
                        </div>
                        <div className="step-devider">
                            Add the first payment method!
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="horizontal-sections">
                        <div className="left-container">
                            <h3>Add payment details</h3>
                            <Elements stripe={stripePromise}>
                                <CompanyDetailsForm
                                    name={name}
                                    surname={surname}
                                    email={email}
                                    onCompanyDetailsReady={handleCompanyDetails}
                                    onFailed={() => setIsRegistering(false)}
                                    onComplete={onComplete}
                                    requirePayment={true}
                                    submitTitle={"Purchase"}
                                    priceId={SUBSCRIPTION_ID}
                                    showCoupon={true}
                                />
                            </Elements>
                        </div>
                        <div className="right-container">
                            {subscriptionInfo && <SubscriptionInfo {...subscriptionInfo} />}
                        </div>
                    </div>
                </div>
                {isRegistering && (
                    <Popup isOpenProp={isRegistering}>
                        <p className="">Registering...</p>
                        <div className="loader"></div>
                    </Popup>)}
            </div>
        </div>
    );
};

export default CompanySetup;