import React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { OnboardingState } from 'src/Components/Onboarding/Enums';

const Container = styled('div')({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 600,
  textAlign: 'center',
});

const MessageText = styled('span')({
  fontSize: '12px',
  fontFamily: 'Inter',
  fontWeight: 600,
  color: '#878787'
});

const SignInButton = styled(Button)({
  color: '#316CE0',
  fontWeight: 600,
  fontFamily: 'Inter',
  fontSize: '12px',
  textDecoration: 'underline',
  textTransform: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const OnboardingPrompt = ({ onClick, onboardingState }) => {
  let elements = [];

  switch (onboardingState) {
    case OnboardingState.CREATE_ACCOUNT:
      elements.push(
        <MessageText>Don't have an account?</MessageText>,
        <SignInButton onClick={onClick}>Sign up</SignInButton>
      );
      break;
    case OnboardingState.FORGOT_PASSWORD:
      elements.push(
        <MessageText>Forgot your password?</MessageText>,
        <SignInButton onClick={onClick}>Reset password</SignInButton>
      );
      break;
    case OnboardingState.SIGN_IN:
      elements.push(
        <MessageText key="message1">Already have an account?</MessageText>,
        <SignInButton key="button1" onClick={onClick}>Sign in</SignInButton>
      );
      break;
    default:
      break;
  }

  return (
    <Container>
      {elements}
    </Container>
  );
};

export default OnboardingPrompt;
