import React from 'react';
import { image_url_for_image } from 'src/Utilities/Utilities'

const ImagePreview = ({ src, alt, className }) => {
  const imageUrl = image_url_for_image(src);
  return (
    <img src={imageUrl} alt={alt} className={className} />
  );
};

export default ImagePreview;