import React from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const StyledTextField = styled(TextField)((props) => ({
    '& .MuiOutlinedInput-root': {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        backgroundColor: 'white',
        borderRadius: '8px',
        '& fieldset': {
            border: 'none',
        },
        '&:hover': {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
            '& fieldset': {
                borderColor: '#ddd',
            }
        },
        '&.Mui-focused': {
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
            '& fieldset': {
                borderColor: '#4a90e2',
            }
        },
        height: props.height ? props.height : undefined,
    },
    '& .MuiInputLabel-root': {
        color: '#9b9b9b',
        fontFamily: 'Inter',
        '&.Mui-focused': {
            color: '#4a90e2',
        },
        '&.MuiInputLabel-shrink': {
            color: '#B5B5BD',
        }
    },
    '& .MuiInputBase-input': {
        fontFamily: 'Inter',
        color: '#000000',
    },
}));

const WhiteTextField = ({ multiline, rows, ...props }) => {
    return (
        <StyledTextField
            {...props}
            multiline={multiline}
            rows={multiline ? rows : 1}
            variant="outlined" />
    );
};

export default WhiteTextField;
