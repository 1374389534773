import React, { useState } from 'react';
import './ConfirmUnsubscribePopupContent.css';

const ConfirmUnsubscribePopupContent = ({ confirmUnsubscribeCallback, clientData, subscriptionId }) => {
  const [email, setEmail] = useState('');
  const messageAddDate = clientData.messageAddDate;
  const expiryDate = new Date((messageAddDate + 30 * 24 * 60 * 60) * 1000)
  const currentDate = new Date();

  const handleUnsubscribe = () => {
    if (confirmUnsubscribeCallback)
      confirmUnsubscribeCallback(email, subscriptionId)
  };

    return (
      <div className="popup-form">
        <label className="popup-form-label">
          Are you sure you want to unsubscribe?
        </label>
        { messageAddDate && expiryDate.getTime() > currentDate.getTime() && (
            <p htmlFor="dsc" className="popup-form-ldsc">
              Unsubscribing will not renew your messages after: {expiryDate.toLocaleString()}. Please note that users will not be able to send any messages after this date.
            </p>
          )
        }
        <p htmlFor="email" className="popup-form-label">
          Enter your email to unsubscribe
        </p>
        <input
          type="email"
          id="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="popup-form-input"
        />
        <button onClick={handleUnsubscribe} className="popup-form-delete-button">
          Unsubscribe
        </button>
      </div>
    );
  };

  export default ConfirmUnsubscribePopupContent