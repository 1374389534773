import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import BlueButton from "src/Elements/BlueButton";
import { AuthContext } from 'src/AuthProvider';
import NotificationsForm from './NotificationsForm';
import notificationExampleIcon from 'src/Assets/new/notificationExampleIcon.jpg';
import { NetworkProvider } from 'src/NetworkProvider';

const Notifications = ({ setLoadingText }) => {
    const classes = useStyles();
    const formRef = useRef(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const [tokenCount, setTokenCount] = useState(null);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [pendingNotification, setPendingNotification] = useState({ title: '', body: '' });

    useEffect(() => {
        fetchPushTokenCount()
    }, []);

    const fetchPushTokenCount = async () => {
        try {
            const tokenCount = await NetworkProvider.get_push_token_count(loginCredentials, setLoginCredentials)
            setTokenCount(tokenCount)
        } catch { }
    }

    const handleConfirmSend = async () => {

        try {
            setLoadingText("Sending...");
            const result = await NetworkProvider.send_notification(loginCredentials, setLoginCredentials, pendingNotification.title, pendingNotification.body);
            setLoadingText(null);
            setConfirmDialogOpen(false);
            formRef.current?.clearFields();

            const success_count = result._success_count
            if (success_count) {
                alert("Notification was sent to " + success_count + " devices")
            }
        } catch (error) {
            setLoadingText(null);
            alert(error)
        }
    };

    const onSubmit = (title, body) => {
        setPendingNotification({ title, body });
        setConfirmDialogOpen(true)
    }

    return (
        <div className={classes.content}>
            <h1 className={classes.title}>{'Notifications'}</h1>
            <p className={classes.subtitle}>Send a notification to all users of your app</p>
            <p className={classes.description}>
                Add a title, and a body (subtitle) to your notification. <br />
                We will attempt to send the notification to {tokenCount} users using your app<br />
                Keep title and body short to avoid truncation<br />
            </p>
            <div className={classes.contentArea}>
                <NotificationsForm ref={formRef} onSubmit={onSubmit} />
                <div className={classes.imageContainer}>
                    <img src={notificationExampleIcon} alt="Notification Example" className={classes.categoriesExampleIcon} />
                    <h3 className={classes.exampleTitle}>Notification example</h3>
                </div>
            </div>
            <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
                <DialogTitle>Confirm Notification Send</DialogTitle>
                <DialogContent>
                    <p>Notification will be sent to {tokenCount} registered users?</p>
                    <BlueButton onClick={handleConfirmSend}>Send</BlueButton>
                </DialogContent>
            </Dialog>
        </div>
    );
};

const useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        width: '100%'
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
        width: '100%'
    },
    title: {
        marginLeft: 20,
        textAlign: 'left',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#16192C'
    },
    subtitle: {
        marginLeft: 20,
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#425466'
    },
    description: {
        marginLeft: 20,
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#737373',
        marginBottom: 30
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    categoriesExampleIcon: {
        maxWidth: 200
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#736F6F',
        marginTop: '10px'
    }
});

export default Notifications;
