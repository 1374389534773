import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const WhiteButton = styled(Button)({
    width: '100% !important',
    backgroundColor: '#FFFFFF !important',
    color: '#16192C !important',
    padding: '10px 20px !important',
    borderRadius: '8px !important',
    cursor: 'pointer !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    fontFamily: 'Inter !important',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1) !important',
    '&:hover': {
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2) !important', 
    },
});


export default WhiteButton;
