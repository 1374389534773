import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const BlueTransparentButton = styled(Button)({
  width: '100% !important',
  backgroundColor: 'transparent !important',
  color: '#455DF1 !important',
  padding: '10px 20px !important',
  borderRadius: '8px !important',
  cursor: 'pointer !important',
  fontSize: '12px !important',
  fontWeight: '600 !important',
  fontFamily: 'Inter !important',
  border: '1px solid #127EEA !important',
  textTransform: 'none !important',
});

  export default BlueTransparentButton;