import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';

const FullScreenLoader = ({ loadingText }) => {

  return (
    <Backdrop
      open={loadingText != null}
      sx={{
        color: '#fff !important',
        zIndex: '10000 !important',
        flexDirection: 'column !important',
        backgroundColor: 'rgba(0, 0, 0, 0.85) !important',
      }}
    >
      <CircularProgress color="inherit" />
      {loadingText && (
        <Typography sx={{ marginTop: 2 }}>{loadingText}</Typography>
      )}
    </Backdrop>
  );
};

export default FullScreenLoader;
