import React, { useState, useEffect, useContext } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { AuthContext } from 'src/AuthProvider';

import PaymentMethodForm from 'src/Components/Payments/PaymentMethodForm';
import { NetworkProvider } from 'src/NetworkProvider';
import { STRIPE_PK_KEY } from 'src/contants';
import CardIcon from 'src/Utilities/CardIcon/CardIcon';
import "./PaymentMethods.css"
import Popup from 'src/Components/Popup/Popup';
import BlueButton from 'src/Elements/BlueButton';
import BlueishButton from 'src/Elements/BlueishButton';

const stripePromise = loadStripe(STRIPE_PK_KEY, { locale:'en' });

const PaymentMethods = ({ setLoadingText }) => {
    const [showAddPayment, setShowAddPayment] = useState(false);
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [customer, setCustomer] = useState(null);
    const [isAddPaymentOpen, setIsAddPaymentOpen] = useState(true);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    useEffect(() => {
        setupPaymentMethods();
    }, []);

    const handleAddPaymentClick = () => {
        setShowAddPayment(true);
    };

    const setDefaultPaymentMethod = async (paymentMethodId) => {
        try {
            setLoadingText("Setting default payment method")
            await NetworkProvider.set_default_payment_method(loginCredentials, setLoginCredentials, paymentMethodId);
            setupPaymentMethods();

        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };

    const setupPaymentMethods = async () => {
        try {
            const customerData = await NetworkProvider.get_customer_info(loginCredentials, setLoginCredentials);
            setCustomer(customerData);

            const paymentMethodsData = await NetworkProvider.get_payment_methods(loginCredentials, setLoginCredentials);
            setPaymentMethods(paymentMethodsData);
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };

    const onPaymentMethodCreated = (paymentMethod) => {
        if (paymentMethods.length == 0) {
            const paymentMethodId = paymentMethod.id;
            setDefaultPaymentMethod(paymentMethodId)
        } else {
            setPaymentMethods([...paymentMethods, paymentMethod]);
        }
        setShowAddPayment(false);
    };

    const handlePaymentMethodDelete = async (paymentMethodId) => {
        try {
            setLoadingText("Deleting payment method")
            await NetworkProvider.delete_payment_method(loginCredentials, setLoginCredentials, paymentMethodId);
            setPaymentMethods(paymentMethods.filter((pm) => pm.id !== paymentMethodId));
            setLoadingText(null)
        } catch (error) {
            setLoadingText(null)
            window.alert(error);
        }
    };

    return (
        <div className='payment-methods-card'>
            <div className='payment-methods-card-header'>
                <div className='payment-methods-card-label'>
                    Payment Methods
                </div>
                <div className='payment-methods-card-add-button'>
                    <BlueButton onClick={handleAddPaymentClick}>Add payment method</BlueButton>
                </div>
            </div>
            <ul className='listDesign'>
                {paymentMethods.map((paymentMethod) => (
                    <li className='listflex' key={paymentMethod.id}>
                        <CardIcon brand={paymentMethod.card.brand} />
                        {paymentMethod.card.brand} **** **** **** {paymentMethod.card.last4}{' '}
                        {paymentMethod.id === customer.invoice_settings.default_payment_method && <span>(default)</span>}
                        {paymentMethod.id !== customer.invoice_settings.default_payment_method && (
                            <div className='payment-methods-card-button'>
                                <BlueButton onClick={() => setDefaultPaymentMethod(paymentMethod.id)}>Set default</BlueButton>
                            </div>
                        )}
                        <div className='payment-methods-card-button'>
                            <BlueishButton onClick={() => handlePaymentMethodDelete(paymentMethod.id)}>Remove</BlueishButton>
                        </div>
                    </li>
                ))}
            </ul>
            {showAddPayment && (
                <Popup onClose={() => setShowAddPayment(false)} isOpenProp={isAddPaymentOpen}>
                    <Elements stripe={stripePromise}>
                        <PaymentMethodForm onPaymentMethodCreate={onPaymentMethodCreated} setLoadingText={setLoadingText} />
                    </Elements>
                </Popup>
            )}
        </div>
    );
};

export default PaymentMethods;