import React, { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import NavigationContext from 'src/Components/Navigation/NavigationContext';
import { ADMIN_URI } from 'src/contants';
import { makeStyles } from '@material-ui/core/styles';
import BlueButton from 'src/Elements/BlueButton';

function Purchasesuccess() {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token;
  const authorData = location.state?.authorData;
  const { setOnboardingState } = useContext(NavigationContext);

  function handleButtonClick() {
    setOnboardingState(2);
    const stateToPass = {
      token: token,
      authorData
  };
    navigate(ADMIN_URI, { state: stateToPass });
  }

  return (
    <div className={classes.thankYouContainer}>
      <div className={classes.thankYou}>
        <h2>Thank you for your purchase!</h2>
        <h3>Your account is ready</h3>
        <div className={classes.toProfile}>
          <BlueButton onClick={handleButtonClick}> Go to my profile</BlueButton>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  thankYouContainer: {
    background: '#252525',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  thankYou: {
    marginTop: '27.5vh',
    textAlign: 'center',
    fontFamily: 'Inter',
    color: '#FFFFFF',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
  toProfile: {
    marginTop: '1rem',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    color: '#4C6FFF',
    fontSize: '1rem',
    cursor: 'pointer',
    fontFamily: 'Inter',
    width: '300px',
    justifyContent: 'center',
  },
  body: {
    backgroundColor: '#252525',
  },
}));

export default Purchasesuccess;
