import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FORGOT_PASSWORD_TITLE, FORGOT_PASSWORD_SUBTITLE, SIGN_IN_URI } from 'src/contants';
import '../SignIn/SignIn.css'

import { useNavigate } from 'react-router-dom';
import { NetworkProvider } from 'src/NetworkProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import { UserTextFields } from '../UserTextfields/UserTextFields.jsx';
import { OnboardingState } from '../Enums';
import OnboardingPrompt from 'src/Elements/OnboardingPrompt';

function ForgotPassword() {

    const navigate = useNavigate();
    const [loadingText, setLoadingText] = useState(null);

    async function handleForgotPassword(formData, captchaToken) {

        if (captchaToken == null) {
            alert("Failed to validate captcha")
            return;
        }

        try {
            await NetworkProvider.validateCaptchaToken(captchaToken)
        } catch (error) {
            alert("Failed to validate captcha ", error)
            return
        }

        setLoadingText("Sending reset link")

        try {
            const { email } = formData;
            await NetworkProvider.send_password_reset_link(email)
        } catch (error) { }

        window.alert("Password reset email will been sent if such email is found in our system");
        setLoadingText(null)
        navigate(SIGN_IN_URI);
    }


    function handleSignIn() {
        navigate(SIGN_IN_URI);
    }

    function onFailedValidatingCaptcha(error) {
        setLoadingText(null);
        alert("Failed to validate captcha" + error)
    }

    return (
        <div className="split-screen-container">
            <Helmet>
                <script type="application/ld+json">
                    {`
                {
                    "@context": "https://schema.org",
                    "@type": "WebPage",
                    "name": "Onboarding",
                    "description": "Introduce Artificial intelligence power. Stay ahead of your competition with the best customer service 24/7.",
                    "url": "${window.location.href}"
                }
            `}
                </script>
            </Helmet>
            <div className="left-section">
                <div className="labels-container">
                    <div className="textContainer">
                        <label className="title-label">{FORGOT_PASSWORD_TITLE}</label>
                        <label className="subtitle-label">{FORGOT_PASSWORD_SUBTITLE}</label>
                    </div>
                </div>
            </div>
            <div className="right-section">
                <div className="title-text">Forgot password</div>
                <UserTextFields
                    onboardingState={OnboardingState.FORGOT_PASSWORD}
                    onSubmit={handleForgotPassword}
                    onValidatingCaptcha={() => setLoadingText("Validating captcha")}
                    onFailedValidatingCaptcha={onFailedValidatingCaptcha}
                />
                <OnboardingPrompt
                    onClick={handleSignIn}
                    onboardingState={OnboardingState.SIGN_IN}
                />
            </div>
            <FullScreenLoader loadingText={loadingText} />
        </div>
    );
}

export default ForgotPassword;