import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditDeletePopover from '../../EditDeletePopover/EditDeletePopover';
import ImagePreview from 'src/Elements/ImagePreview';

const EditableTrainingPlan = ({ plan, onPlanClick, onDeleteClick, onEditClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const onThreeDotsClick = (event) => {
    event.stopPropagation(); 
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <div key={plan.id} className={classes.planBox} onClick={() => onPlanClick(plan)}>
        <ImagePreview src={plan.image} alt={plan.title} className={classes.planImage} />
        <div className={classes.titleContainer}>
          <h2 className={classes.planTitle}>{plan.name}</h2>
          <div className={classes.dots} onClick={(event) => onThreeDotsClick(event)}>
            ...
          </div>
        </div>
      </div>
      <EditDeletePopover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onEditClick={() => onEditClick(plan)}
        onDeleteClick={() => onDeleteClick(plan)}
      />
    </div>
  );
};

const useStyles = makeStyles({
  planBox: {
    position: 'relative',
    width: 253,
    height: 185,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 5,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  planImage: {
    width: '100%',
    height: '90%',
    borderRadius: 5,
    objectFit: 'cover'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%', 
    height: '10%'
  },
  planTitle: {
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 600,
    marginBottom: 0,
    color: '#000000'
  },
  dots: {
    cursor: 'pointer',
    padding: '0 5px'
  }
});

export default EditableTrainingPlan;
