import React, { useState, useEffect } from 'react';
import './UserTextFields.css';
import CryptoJS from 'crypto-js';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { DEFAULT_LOGIN } from 'src/contants';
import { OnboardingState } from '../Enums';

import BlueButton from 'src/Elements/BlueButton';
import TermsCheckbox from 'src/Elements/TermsCheckbox';


export const UserTextFields = ({ onboardingState, onSubmit, onValidatingCaptcha, onFailedValidatingCaptcha }) => {
  const [formData, setFormData] = useState(JSON.parse(DEFAULT_LOGIN));

  const [errors, setErrors] = useState({
    name: '',
    surname: '',
    email: '',
    password: '',
    reEnterPassword: ''
  });

  const [isChecked, setIsChecked] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const isCreateAccount = onboardingState == OnboardingState.CREATE_ACCOUNT
  const isForgotPassword = onboardingState == OnboardingState.FORGOT_PASSWORD
  const isSignIn = onboardingState == OnboardingState.SIGN_IN
  const isResetPassword = onboardingState == OnboardingState.RESET_PASSWORD

  useEffect(() => {
    validateForm();
  }, [formData]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let isValid = true
    switch (onboardingState) {
      case OnboardingState.CREATE_ACCOUNT:
        isValid = validateForm()
        break
      case OnboardingState.FORGOT_PASSWORD:
        isValid = validateForm("email")
        break
      case OnboardingState.RESET_PASSWORD:
        isValid = validateForm("password") && validateForm("reEnterPassword")
        break
      case OnboardingState.SIGN_IN:
        isValid = validateForm("email") && validateForm("password")
        break
      default:
    }

    if (isValid && (isCreateAccount ? isChecked : true)) {
      const submitData = { ...formData };

      submitData.password = CryptoJS.SHA256(submitData.password).toString();

      let captchaToken = null
      if (onValidatingCaptcha) {
        onValidatingCaptcha();
      }

      try {
        if (isCreateAccount) {
          captchaToken = await executeRecaptcha("create");
          submitData.reEnterPassword = CryptoJS.SHA256(submitData.reEnterPassword).toString();
        } else {
          captchaToken = await executeRecaptcha("login");
        }
      } catch (error) {
        if (onFailedValidatingCaptcha) {
          onFailedValidatingCaptcha(error)
        }
        return;
      }

      onSubmit(submitData, captchaToken);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const getSubmitTitle = () => {
    switch (onboardingState) {
      case OnboardingState.CREATE_ACCOUNT:
        return "Register"
      case OnboardingState.FORGOT_PASSWORD:
        return "Send reset link"
      case OnboardingState.SIGN_IN:
        return "Sign in"
      case OnboardingState.RESET_PASSWORD:
        return "Submit"
      default:
        return ""
    }
  }

  const validateForm = (fieldName) => {
    let newErrors = { ...errors }; // Assuming errors is your current state of form errors
    let isValid = true; // Start assuming the form is valid

    const validateField = (name) => {
      switch (name) {
        case 'name':
          newErrors.name = formData.name.trim() ? '' : 'Name is required';
          break;
        case 'surname':
          newErrors.surname = formData.surname.trim() ? '' : 'Surname is required';
          break;
        case 'email':
          newErrors.email = formData.email.trim()
            ? (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(formData.email)
              ? ''
              : 'Invalid email address')
            : 'Email is required';
          break;
        case 'password':
          newErrors.password = formData.password.trim() ? '' : 'Password is required';
          break;
        case 'reEnterPassword':
          newErrors.reEnterPassword = formData.reEnterPassword.trim()
            ? (formData.password === formData.reEnterPassword
              ? ''
              : 'Passwords do not match')
            : 'You must confirm your password';
          break;
        case 'terms':
          newErrors.terms = isChecked ? '' : 'You must agree to the terms and conditions';
          break;
        default:
          break;
      }
      // Only set isValid to false if there's an error. Never reset it to true here.
      if (newErrors[name]) {
        isValid = false;
      }
    };

    // If fieldName is provided, validate only that field; otherwise, validate all fields
    if (fieldName) {
      validateField(fieldName);
    } else {
      // Validate all fields
      Object.keys(formData).forEach((name) => validateField(name));
      // Additionally, validate 'terms' if it's a condition for account creation
      if (isCreateAccount) validateField('terms');
    }

    setErrors(newErrors); // Update the state with the new errors
    return isValid; // Return the overall form validity
  };

  return (
    <div className="user-text-fields">
      <form onSubmit={handleSubmit}>
        {isCreateAccount && (
          <>
            <div className="text-field-container">
              <label>Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your name"
              />
              {errors.name && <span className="error-text">{errors.name}</span>}
            </div>
            <div className="text-field-container">
              <label>Surname</label>
              <input
                type="text"
                name="surname"
                value={formData.surname}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your surname"
              />
              {errors.surname && <span className="error-text">{errors.surname}</span>}
            </div>
            <div className="text-field-container">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your email"
              />
              {errors.email && <span className="error-text">{errors.email}</span>}
            </div>
            <div className="text-field-container">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your password"
              />
              {errors.password && <span className="error-text">{errors.password}</span>}
            </div>
            <div className="text-field-container">
              <label>Re-enter Password</label>
              <input
                type="password"
                name="reEnterPassword"
                value={formData.reEnterPassword}
                onChange={handleChange}
                className="text-field"
                placeholder="Repeat your password"
              />
              {errors.reEnterPassword && <span className="error-text">{errors.reEnterPassword}</span>}
            </div>
            <TermsCheckbox
              isChecked={isChecked}
              handleCheckboxChange={handleCheckboxChange}
              errors={errors}
            />
          </>
        )}
        {isSignIn && (
          <>
            <div className="text-field-container">
              <label>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your email"
              />
              {errors.email && <span className="error-text">{errors.email}</span>}
            </div>
            <div className="text-field-container">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your password"
              />
              {errors.password && <span className="error-text">{errors.password}</span>}
            </div>
          </>
        )}
        {isForgotPassword && (
          <div className="text-field-container">
            <label>Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="text-field"
              placeholder="Enter your email"
            />
            {errors.email && <span className="error-text">{errors.email}</span>}
          </div>
        )}
        {isResetPassword && (
          <>
            <div className="text-field-container">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="text-field"
                placeholder="Enter your new password"
              />
              {errors.password && <span className="error-text">{errors.password}</span>}
            </div>
            <div className="text-field-container">
              <label>Re-enter Password</label>
              <input
                type="password"
                name="reEnterPassword"
                value={formData.reEnterPassword}
                onChange={handleChange}
                className="text-field"
                placeholder="Confirm your new password"
              />
              {errors.reEnterPassword && <span className="error-text">{errors.reEnterPassword}</span>}
            </div>
          </>
        )}
        <BlueButton type="submit">
          {getSubmitTitle()}
        </BlueButton>
      </form>
    </div>
  );



};

