import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'; 
import backIcon from 'src/Assets/new/backIcon.svg';

const useStyles = makeStyles((theme) => ({
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    marginLeft: theme.spacing(1),
    fontSize: 12,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#4B68FF'
  },
}));

const BackButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} edge="start" color="inherit" aria-label="back" className={classes.button}>
      <div className={classes.buttonContent}>
        <img src={backIcon} alt="Back" />
        <span className={classes.text}>Back</span>
      </div>
    </IconButton>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default BackButton;
