import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import './Admin.css';
import { CREATE_ACCOUNT_URI } from 'src/contants';

import { NetworkProvider } from '../../NetworkProvider';
import CategoriesReducer from 'src/Utilities/CategoriesReducer';
import { AuthContext } from 'src/AuthProvider';
import FullScreenLoader from 'src/Elements/FullScreenLoader';

import Categories from './Categories/Categories';
import Recipes from './Recipes/Recipes';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import MyInvoices from './MyInvoices/MyInvoices';
import MyCompanyInfo from './MyCompanyInfo/MyCompanyInfo';
import DailyVideo from './DailyVideo/DailyVideo';
import Greeting from './Greeting/Greeting';
import Notifications from './Notifications/Notifications';
import MenuButton from 'src/Elements/MenuButton';

import NavigationContext from '../Navigation/NavigationContext';

import Popup from '../Popup/Popup';
import ConfirmUnsubscribePopupContent from 'src/Utilities/Popups/ConfirmUnsubscribe/ConfirmUnsubscribePopupContent';

function Admin() {

  const navigate = useNavigate();
  const [selected, setSelected] = useState(1);
  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const [showContent, setShowContent] = useState(false);
  const { loginCredentials, setLoginCredentials, clearCredentials } = useContext(AuthContext);
  const [loadingText, setLoadingText] = useState(null);
  const location = useLocation();

  const [authorData, setAuthorData] = useState(location.state ? location.state.authorData : null);

  // Unsubscribe alert
  const [unsubscribeRequestSubId, setUnsubscribeRequestSubId] = useState(null);
  const [isUnsubscribeOpen, setIsUnsubscribeOpen] = useState(true);

  const [categories, setCategories] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [foodCategories, setFoodCategories] = useState([]);

  const { setOnboardingState } = useContext(NavigationContext);

  const handleWindowResize = () => {
    setCollapsed(window.innerWidth < 768);
  };

  useEffect(() => {
    fetchCategories();
    fetchRecipes();
    fetchAuthorIfNeeded();
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  const handleItemClick = (item) => {
    setSelected(item);
    if (collapsed) {
      setShowContent(true);
    }
  };

  const handleBackClick = () => {
    setShowContent(false);
  };

  const handleCloseUnsubscribe = () => {
    setUnsubscribeRequestSubId(null);
  };

  const onUnsubscribeRequested = (subscriptionId) => {
    setUnsubscribeRequestSubId(subscriptionId)
  };

  const confirmUnsubscribeCallback = (email, subscriptionId) => {
    /*
    if (!subscriptionId) {
      return
    }

    const validatedEmail = validated_email(email);

    NetworkProvider.cancelSubscription(clientData.token, validatedEmail, subscriptionId)
      .then(data => {
        setUnsubscribeRequestSubId(null);
      })
      .catch(error => {
        window.alert(error);
      });*/
  }

  const fetchCategories = async () => {
    try {
      const categories = await NetworkProvider.get_categories(loginCredentials, setLoginCredentials)
      setCategories(CategoriesReducer.sortedCategories(categories))
    } catch (error) {
      window.alert(error);
    }
  };

  const fetchRecipes = async () => {
    try {
      const recipes = await NetworkProvider.get_recipes(loginCredentials, setLoginCredentials)
      setRecipes(CategoriesReducer.sortedRecipes(recipes))

      const foodCategories = await NetworkProvider.get_food_categories(loginCredentials, setLoginCredentials)
      setFoodCategories(CategoriesReducer.sortByIndex(foodCategories))
    } catch (error) {
      window.alert(error);
    }
  };

  const fetchAuthorIfNeeded = async () => {
    if (!authorData) {
      const newAuthorData = await NetworkProvider.get_author(loginCredentials, setLoginCredentials);
      setAuthorData(newAuthorData);
    }
  }

  const LogoutButton = () => {
    const handleLogout = async () => {
      clearCredentials();
      setOnboardingState(0)
      navigate(CREATE_ACCOUNT_URI);
    };

    return (
      <button onClick={handleLogout}>
        Log out
      </button>
    );
  };

  return (
    <div className="admin-content">
      <Helmet>
        <script type="application/ld+json">
          {`
                  {
                      "@context": "https://schema.org",
                      "@type": "WebPage",
                      "name": "Admin Panel",
                      "description": "appscribe.io Admin panel",
                      "url": "${window.location.href}",
                      "about": {
                          "@type": "Organization",
                          "name": "Appscribe.io",
                          "description": "Your Easy Fitness App Solution",
                          "address": {
                              "@type": "PostalAddress",
                              "streetAddress": "Beberbeķu iela 46",
                              "addressLocality": "Rīga",
                              "addressRegion": "Vidzeme",
                              "postalCode": "LV-1006",
                              "addressCountry": "Latvia"
                          },
                          "vatID": "LV40203159237"
                      }
                  }
              `}
        </script>
      </Helmet>
      <div className={`side-menu ${collapsed && showContent ? 'hidden' : ''}`}>
        <div className="menuItemContainer">
          <div className='logoHolder'>
            {/*<img src={signupbaner} alt="Done Step" className="menuIcon" />*/}
          </div>

          <button className={selected === 1 ? 'selected' : ''} onClick={() => handleItemClick(1)}>
            Training plans
          </button>

          <button className={selected === 2 ? 'selected' : ''} onClick={() => handleItemClick(2)}>
            Daily video
          </button>

          <button className={selected === 3 ? 'selected' : ''} onClick={() => handleItemClick(3)}>
            Greeting
          </button>

          <button className={selected === 4 ? 'selected' : ''} onClick={() => handleItemClick(4)}>
            Recipes
          </button>

          <button className={selected === 5 ? 'selected' : ''} onClick={() => handleItemClick(5)}>
            Payment methods
          </button>

          <button className={selected === 6 ? 'selected' : ''} onClick={() => handleItemClick(6)}>
            Invoices
          </button>

          <button className={selected === 7 ? 'selected' : ''} onClick={() => handleItemClick(7)}>
            Billing info
          </button>

          <button className={selected === 8 ? 'selected' : ''} onClick={() => handleItemClick(8)}>
            Notifications
          </button>
          <LogoutButton />
        </div>
      </div>
      <div className={`content ${collapsed && !showContent ? 'hidden' : ''}`}>
        {collapsed && (
          <div className='bck-btn'>
            <MenuButton onClick={handleBackClick} />
          </div>
        )}
        {selected === 1 && <Categories categories={categories} setLoadingText={setLoadingText} />}
        {selected === 2 && <DailyVideo setLoadingText={setLoadingText} />}
        {selected === 3 && <Greeting setLoadingText={setLoadingText} />}
        {selected === 4 && <Recipes
          recipes={recipes}
          setRecipes={setRecipes}
          foodCategories={foodCategories}
          setFoodCategories={setFoodCategories}
          setLoadingText={setLoadingText}
        />}
        {selected === 5 && <PaymentMethods setLoadingText={setLoadingText} />}
        {selected === 6 && <MyInvoices />}
        {selected === 7 && <MyCompanyInfo setLoadingText={setLoadingText} authorData={authorData} />}
        {selected === 8 && <Notifications setLoadingText={setLoadingText} />}
      </div>
      {unsubscribeRequestSubId && (
        <Popup onClose={handleCloseUnsubscribe} isOpenProp={isUnsubscribeOpen}>
          <ConfirmUnsubscribePopupContent
            confirmUnsubscribeCallback={confirmUnsubscribeCallback}
            subscriptionId={unsubscribeRequestSubId} />
        </Popup>
      )
      }
      <FullScreenLoader loadingText={loadingText} />
    </div>
  );
}

export default Admin;