import React, { useState, useEffect, useContext } from 'react';
import { NetworkProvider } from 'src/NetworkProvider';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PK_KEY } from 'src/contants';
import CompanyDetailsForm from 'src/Components/Onboarding/CompanySetup/CompanyDetailsForm/CompanyDetailsForm';
import { AuthContext } from 'src/AuthProvider';
import { getNameFromAuthorData, getSurnameFromAuthorData } from 'src/Utilities/Utilities';

const stripePromise = loadStripe(STRIPE_PK_KEY);

function MyCompanyInfo({ authorData, setLoadingText }) {
  const [companyDetails, setCompanyDetails] = useState(null);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
  const classes = useStyles();

  useEffect(() => {
    fillCompanyDetails();
  }, []);

  const fillCompanyDetails = async () => {
    try {
      const data = await NetworkProvider.fetch_billing_details(loginCredentials, setLoginCredentials);
      setCompanyDetails(data);
    } catch (error) {
      window.alert(error);
    }
  };

  const companyDetailsReady = async (companyDetails) => {
    try {
      setLoadingText("Updating details")
      await NetworkProvider.update_billing_details(loginCredentials, setLoginCredentials, companyDetails);

      setLoadingText(null)
      window.alert("Details updated");
    } catch (error) {
      setLoadingText(null)
      window.alert(error);
    }
  }

  return (
    <div className={classes.content}>
      <h3>Adjust details</h3>
      <Elements stripe={stripePromise}>
        <CompanyDetailsForm
          onCompanyDetailsReady={companyDetailsReady}
          initialDetails={companyDetails}
          submitTitle={"Update details"}
          requirePayment={false}
          editName={true}
          name={getNameFromAuthorData(authorData)}
          surname={getSurnameFromAuthorData(authorData)}
          email={authorData.mail}
        />
      </Elements>
    </div>
  );
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%'
  }
});

export default MyCompanyInfo