import React, { useState, useEffect, useContext } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import emptyRecipeIcon from 'src/Assets/new/emptyRecipeIcon.png';
import createRecipeIcon from 'src/Assets/new/createRecipeIcon.png';
import { makeStyles } from '@material-ui/core/styles';
import Recipe from './Recipe';
import EditAddRecipe from './EditAddRecipe';
import { AuthContext } from 'src/AuthProvider';
import { NetworkProvider } from 'src/NetworkProvider';
import FoodCategories from './FoodCategories/FoodCategories';
import BackButton from 'src/Elements/BackButton';
import DeleteConfirmDialog from 'src/Utilities/Popups/DeleteConfirmDialog';

const StateEnum = {
    RECIPES: 0,
    EDITADDRECIPE: 1,
    FOODCATEGORIES: 2
};

const Recipes = ({ title, recipes, setRecipes, openCategory, foodCategories, setFoodCategories, setLoadingText, onBack }) => {

    const [state, setState] = useState(foodCategories.length > 0 && onBack == null ? StateEnum.FOODCATEGORIES : StateEnum.RECIPES);
    const classes = useStyles();
    const [openRecipe, setOpenRecipe] = useState(null);
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);
    const [filteredRecipes, setFilteredRecipes] = useState([]);
    const [recipeToDelete, setRecipeToDelete] = useState(null);

    useEffect(() => {
        if (openCategory) {
            const filteredRecipes = recipes.filter(recipe => recipe.foodcategoryid === openCategory.id);
            setFilteredRecipes(filteredRecipes)
        }
    }, [recipes]);

    const getRecipes = () => {
        if (openCategory) {
            return filteredRecipes;
        } else {
            return recipes
        }
    }

    const onRecipeEditClick = (recipe) => {
        setOpenRecipe(recipe);
        setState(StateEnum.EDITADDRECIPE);
    }

    const onRecipeDeleteClick = async () => {

        if (!recipeToDelete) {
            return;
        }

        setLoadingText("Deleting recipe...")
        try {
            await NetworkProvider.delete_recipe(loginCredentials, setLoginCredentials, recipeToDelete);

            if (recipeToDelete.image != "") {
                setLoadingText("Deleting recipe image...")

                try {
                    await NetworkProvider.delete_image(loginCredentials, setLoginCredentials, recipeToDelete.image)
                } catch { }
            }
            const updatedRecipes = recipes.filter(recipe => recipe.id !== recipeToDelete.id);
            setRecipes(updatedRecipes)
            setRecipeToDelete(null)
            setLoadingText(null)
        } catch (error) {
            setRecipeToDelete(null)
            setLoadingText(null)
            alert(error)
        }
    }

    const onRecipeClose = (recipeToClose) => {
        setState(StateEnum.RECIPES);

        if (recipeToClose) {
            const existingRecipeIndex = recipes.findIndex((recipe) => recipe.id === recipeToClose.id);

            // If exists update
            if (existingRecipeIndex !== -1) {
                const updatedRecipes = [...recipes];

                const openRecipeIndex = recipes.findIndex((r) => r.id === openRecipe?.id);
                if (openRecipeIndex) {
                    updatedRecipes[openRecipeIndex] = null
                }

                updatedRecipes[existingRecipeIndex] = recipeToClose;

                setRecipes(updatedRecipes);
            } else {

                //otherwise remove old recipe add new one in place
                const openRecipeIndex = recipes.findIndex((r) => r.id === openRecipe?.id);
                let updatedRecipes;

                // Check if recipe exists
                if (openRecipeIndex !== -1) {
                    // Remove the element without mutating the original array
                    updatedRecipes = recipes.filter((_, index) => index !== openRecipeIndex);
                } else {
                    // If the recipe doesn't need to be removed, use the original array
                    updatedRecipes = [...recipes];
                }

                // Add the new recipe
                updatedRecipes.push(recipeToClose);

                // Update the state
                setRecipes(updatedRecipes);

            }
        }

        setOpenRecipe(null);
    };

    const RecipesListContent = () => {

        if (getRecipes().length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        <img src={emptyRecipeIcon} alt="Recipe Example" className={classes.categoriesExampleIcon} />
                        <h3 className={classes.exampleTitle}>Lets add your first recipe</h3>
                        <BlueButton onClick={() => setState(StateEnum.EDITADDRECIPE)}>Add Recipe</BlueButton>
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    <h2 className={classes.mainTitle}>{title ? title : "Recipes"}</h2>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setState(StateEnum.FOODCATEGORIES)}>View food categories</BlueButton>
                        </div>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setState(StateEnum.EDITADDRECIPE)}>Add recipe</BlueButton>
                        </div>
                    </header>
                    <h2 className={classes.categoriesTitle}>Recipes</h2>
                    <div className={classes.contentArea}>
                        <div className={classes.categoryList}>
                            {getRecipes().map((recipe) => (
                                <Recipe
                                    recipe={recipe}
                                    onEditClick={() => onRecipeEditClick(recipe)}
                                    onDeleteClick={() => setRecipeToDelete(recipe)}
                                />
                            ))}
                        </div>
                        { /*<div className={classes.exampleContainer}>
                            <h3 className={classes.exampleTitle}>Example recipes</h3>
                            <img src={createRecipeIcon} alt="Recipes Example" className={classes.categoriesExampleIcon} />
                        </div>*/}
                    </div>
                    <DeleteConfirmDialog
                        open={recipeToDelete != null}
                        subtitle={recipeToDelete ? "Are you sure you want to delete " + recipeToDelete.name + "?" : null}
                        onConfirm={() => onRecipeDeleteClick()}
                        onCancel={() => setRecipeToDelete(null)}
                    />
                </div>
            )
        }
    };

    return (
        <React.Fragment>
            {state === StateEnum.RECIPES &&
                (
                    <>
                        {onBack && <BackButton onClick={onBack} />}
                        <RecipesListContent />
                    </>
                )}
            {state === StateEnum.EDITADDRECIPE &&
                <EditAddRecipe
                    recipe={openRecipe}
                    openCategory={openCategory}
                    foodCategories={foodCategories}
                    onClose={onRecipeClose}
                    setLoadingText={setLoadingText}
                />}
            {state === StateEnum.FOODCATEGORIES &&
                (<FoodCategories
                    foodCategories={foodCategories}
                    setFoodCategories={setFoodCategories}
                    setLoadingText={setLoadingText}
                    onClose={() => setState(StateEnum.RECIPES)}
                    recipes={recipes}
                    setRecipes={setRecipes}
                />
                )}
        </React.Fragment>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000'
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    headerButton: {
        marginLeft: '5px',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%'
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    }
});

export default Recipes;
