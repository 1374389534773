import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import WhiteButton from 'src/Elements/WhiteButton';
import { Container } from '@material-ui/core';
import WhiteTextField from 'src/Elements/WhiteTextField';
import BlueButton from 'src/Elements/BlueButton';
import ImagePreview from 'src/Elements/ImagePreview';

const AddEditPlan = ({ editPlan, onPlanEdited, onPlanAdded }) => {
    const classes = useStyles();
    const inputFileRef = useRef();
    const [planName, setPlanName] = useState('');
    const [planType, setPlanType] = useState('');
    const [planDifficulty, setPlanDifficulty] = useState('');
    const [planDuration, setPlanDuration] = useState('');
    const [planDescription, setPlanDescription] = useState('');
    const [error, setError] = useState('');
    const [preview, setPreview] = useState('');
    const [image, setImage] = useState(null);

    const [localPlan, setLocalPlan] = useState(null);

    useEffect(() => {

        if (editPlan == null) {
            return;
        }
        setLocalPlan(localPlan);
        setPlanName(editPlan.name);
        setPreview(editPlan.image);
        setPlanDescription(editPlan.description);
        setPlanType(editPlan.type);
        setPlanDifficulty(editPlan.difficulty)
        setPlanDuration(editPlan.duration)
    }, [editPlan]);

    const handlePlanNameChange = (event) => {
        const newName = event.target.value;
        setPlanName(newName);

        if (error) {
            setError(false);
        }
    };

    const handlePlanDescriptionChange = (event) => {
        const newDescription = event.target.value;
        setPlanDescription(newDescription);

        if (error) {
            setError(false);
        }
    };

    const handlePlanTypeChange = (event) => {
        const newPlanType = event.target.value;
        setPlanType(newPlanType);

        if (error) {
            setError(false);
        }
    };

    const handlePlanDifficultyChange = (event) => {
        const newDifficulty = event.target.value;
        setPlanDifficulty(newDifficulty);

        if (error) {
            setError(false);
        }
    };

    const handlePlanDurationyChange = (event) => {
        const newDuration = event.target.value;
        setPlanDuration(newDuration);

        if (error) {
            setError(false);
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type.substr(0, 5) === 'image') {
            setImage(file);
            setPreview(URL.createObjectURL(file));
        } else {
            setImage(null);
            setPreview(null);
        }
    };

    const handleUploadImage = () => {
        inputFileRef.current.click();
    };

    const handleDoneClick = () => {
        if (editPlan) {
            const editedPlan = {
                ...editPlan,
                name: planName,
                description: planDescription,
                type: planType,
                difficulty: planDifficulty,
                duration: planDuration
            }

            onPlanEdited(editedPlan, image);
        } else {
            if (planName.length < 4) {
                setError('Plan name must be at least 4 characters');
            } else {
                setError('');
                onPlanAdded(planName, planDescription, planType, planDifficulty, planDuration, image)
            }
        }
    };

    return (
        <div className={classes.workoutPlanContent}>
            <div className={classes.workoutPlanContainer}>
                <h1 className={classes.title}>{editPlan ? 'Edit Workout Plan' : 'Adding New Workout Plan'}</h1>
                <p className={classes.subtitle}>Workout name</p>

                {editPlan ?
                    <p className={classes.description}>Change the name of your workout plan'</p>
                    :
                    <p className={classes.description}>Add a name for your workout plan, for example, '12-week program' or 'Shoulder workout.'</p>}

                <WhiteTextField
                    label="Enter plan name"
                    variant="outlined"
                    className={classes.textField}
                    error={Boolean(error)}
                    helperText={error}
                    value={planName}
                    onChange={handlePlanNameChange}
                />
                <WhiteTextField
                    label="Enter plan description"
                    variant="outlined"
                    className={classes.textField}
                    multiline 
                    rows={4}
                    error={Boolean(error)}
                    helperText={error}
                    value={planDescription}
                    onChange={handlePlanDescriptionChange}
                />
                <WhiteTextField
                    label="Enter plan type"
                    variant="outlined"
                    className={classes.textField}
                    rows={1}
                    error={Boolean(error)}
                    helperText={error}
                    value={planType}
                    onChange={handlePlanTypeChange}
                />
                <WhiteTextField
                    label="Enter plan difficulty"
                    variant="outlined"
                    className={classes.textField}
                    rows={1}
                    error={Boolean(error)}
                    helperText={error}
                    value={planDifficulty}
                    onChange={handlePlanDifficultyChange}
                />
                <WhiteTextField
                    label="Enter average daily duration (in minutes)"
                    variant="outlined"
                    className={classes.textField}
                    rows={1}
                    error={Boolean(error)}
                    helperText={error}
                    value={planDuration}
                    onChange={handlePlanDurationyChange}
                />
                <Container className={classes.imageUploadContainer}>
                    {preview && (
                        <ImagePreview src={preview} alt="preview" className={classes.imagePreview} />
                    )}
                    <p className={classes.imageUploadTitle}>Workout image preview</p>
                    <div className={classes.uploadButtonContainer}>
                        <input
                            type="file"
                            onChange={handleImageChange}
                            ref={inputFileRef}
                            style={{ display: 'none' }}
                        />
                        <WhiteButton onClick={handleUploadImage}>Upload</WhiteButton>
                    </div>
                </Container>
                <div className={classes.continueButtonContainer}>
                    <div className={classes.continueButton}>
                        <BlueButton onClick={handleDoneClick}>{editPlan ? 'Update plan' : 'Continue'}</BlueButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

const useStyles = makeStyles(() => ({
    workoutPlanContent: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingRight: 20,
    },
    workoutPlanContainer: {
        width: '70%',
    },
    title: {
        textAlign: 'left',
        fontSize: 16,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#16192C',
    },
    subtitle: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 500,
        color: '#425466',
    },
    description: {
        textAlign: 'left',
        fontSize: 14,
        fontFamily: 'Inter',
        fontWeight: 400,
        color: '#737373',
        marginBottom: 30
    },
    textField: {
        width: '100%',
        marginBottom: 20,
    },
    imageUploadContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 20,
        backgroundColor: 'white',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
        padding: 20
    },
    imagePreview: {
        width: 113,
        height: 113,
        borderRadius: '8px',
    },
    imageUploadTitle: {
        fontFamily: 'Inter',
        fontWeight: 600,
        fontSize: '16px',
        color: '#16192C',
    },
    uploadButtonContainer: {
        width: '100px'
    },
    continueButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%'
    }
}));

export default AddEditPlan;
