import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const AddWeekButton = styled(Button)({
    width: '100% !important',
    backgroundColor: 'transparent !important',
    color: '#3F6EE8 !important',
    padding: '10px 20px !important',
    borderRadius: '8px !important',
    cursor: 'pointer !important',
    fontSize: '12px !important',
    fontWeight: '600 !important',
    fontFamily: 'Inter !important',
    textTransform: 'none !important',
    border: '1px dotted !important'
});

export default AddWeekButton;