import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import "./Popup.css"
import { useSpring, animated } from 'react-spring';

const Popup = ({ children, isOpenProp, onClose }) => {
  const [isRendered, setIsRendered] = useState(isOpenProp);

  const defaultStyle = { opacity: 0, transform: 'translateY(100vh)' };
  const transitionInStyle = { opacity: 1, transform: 'translateY(0)' };
  const transitionOutStyle = { opacity: 0, transform: 'translateY(200vh)' };

  const { opacity, transform } = useSpring({
    from: defaultStyle,
    to: isOpenProp ? transitionInStyle : transitionOutStyle
  });

  useEffect(() => {
    if (isOpenProp) 
        setIsRendered(isOpenProp);
  }, [isOpenProp]);

  return (
    isRendered &&
    ReactDOM.createPortal(
      <div className="popup-overlay">
        <animated.div className="popup-content" style={{ opacity, transform }}>
          <div className="popup-children-content">{children}</div>
          {onClose && (
            <button className="popup-close-button" onClick={onClose}>
              Close
            </button>
          )}
        </animated.div>
      </div>,
      document.body
    )
  );
};

export default Popup;