import React from 'react';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Link, Typography } from '@mui/material';
import { styled } from '@mui/system';

const HOMEPAGE_URL = "https://yourhomepage.com";

const CustomCheckbox = styled(Checkbox)({
  '&&': {
    color: '#3F6EE8',
  }
});

const CustomLink = styled(Link)({
  color: 'black',
  textDecoration: 'underline',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 700,
});

const CustomFormControlLabel = styled(FormControlLabel)({
  '&&': {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    alignItems: 'flex-start',
  }
});

const CustomText = styled('span')({
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: 400,
});

const CustomFormHelperText = styled(FormHelperText)({
  color: 'red',
  fontFamily: 'Inter',
  fontSize: '14px',
});

const TermsCheckbox = ({ isChecked, handleCheckboxChange, errors }) => (
  <FormControl error={!!errors.terms}>
    <CustomFormControlLabel
      control={
        <CustomCheckbox
          onChange={handleCheckboxChange}
          checked={isChecked}
          name="termsCheckbox"
        />
      }
      label={
        <>
          <CustomText variant="body2">
            By creating an account you agree to{' '}
          </CustomText>
          <CustomLink href={HOMEPAGE_URL + "/terms-of-use"} target="_blank" rel="noopener noreferrer">
            Terms and conditions
          </CustomLink>{' '}
          <CustomText variant="body2">
            {' '}and{' '}
          </CustomText>
          <CustomLink href={HOMEPAGE_URL + "/privacy-policy"} target="_blank" rel="noopener noreferrer">
            Privacy policy
          </CustomLink>
        </>
      }
      labelPlacement="end" // Add this line
    />
    {errors.terms && <CustomFormHelperText>{errors.terms}</CustomFormHelperText>}
  </FormControl>
);

export default TermsCheckbox;
