import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types'; 
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
    button: {
        '&:hover': {
          borderRadius: '4px',
          backgroundColor: 'white',
          transition: 'border-radius 0.3s ease'
        },
      },
  buttonContent: {
    display: 'flex',
    alignItems: 'center',
    color: '#DF2E2E',
  },
  text: {
    marginLeft: theme.spacing(1),
    fontSize: 12,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#DF2E2E'
  },
}));

const DeleteButton = ({ onClick }) => {
  const classes = useStyles();

  return (
    <IconButton onClick={onClick} edge="start" color="inherit" aria-label="back" className={classes.button}>
      <div className={classes.buttonContent}>
        <DeleteIcon />
        <span className={classes.text}>Delete</span>
      </div>
    </IconButton>
  );
};

DeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired
};

export default DeleteButton;
