import React, { useState, useEffect, useContext } from 'react';
import { NetworkProvider } from 'src/NetworkProvider';
import "./MyInvoices.css"
import invoicesSectionIcon from "../../../Assets/invoices-section-icon.svg";
import { AuthContext } from 'src/AuthProvider';
import BlueButton from 'src/Elements/BlueButton';

function MyInvoices() {
  const [invoices, setInvoices] = useState([]);
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  useEffect(() => {
    listInvoices();
  }, []);

  const listInvoices = async () => {
    NetworkProvider.list_invoices(loginCredentials, setLoginCredentials)
      .then((data) => {
        setInvoices(data.data);
      })
      .catch((error) => {
        window.alert(error);
      });
  };

  return (
    <div className='invoice-container'>
      <div className='PageName'>My Invoices</div>
      {invoices.length === 0 ? (
        <p>Fetching invoices</p>
      ) : (
        <table className='table-design'>
          <thead>
            <tr>
              <th>Invoice</th>
              <th>Date Created</th>
              <th>Amount</th>
              <th>State</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {invoices.map((invoice) => (
              <tr key={invoice.id}>
                <td className='cellDesign'><img src={invoicesSectionIcon} alt="Done Step image" className="Done-image" />{invoice.customer_name}</td>
                <td>{new Date(invoice.created * 1000).toLocaleDateString()}</td>
                <td>
                  {(invoice.amount_due / 100).toFixed(2)} {invoice.currency.toUpperCase()}
                </td>
                <td><label className='statusLabel'>{invoice.status}</label></td>
                <td>
                  <a href={invoice.hosted_invoice_url} className='linkunderline' target="_blank" rel="noreferrer">
                    <div className='invoiceButtonDesign'>
                      <BlueButton>View Invoice</BlueButton>
                    </div>
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MyInvoices;