import * as Sentry from '@sentry/react';

export class SentryEventProvider {

    static sendFailedAddRecipe(recipe, loginCredentials) {
        Sentry.captureEvent({
            message: 'Failed to add recipe ' + recipe.name + ' for author ' + loginCredentials.authorId,
            level: 'error',
            tags: {
                feature: 'recipes',
            },
            extra: recipe
        });
    }

    static sendFailedDeleteRecipe(recipe, loginCredentials) {
        Sentry.captureEvent({
            message: 'Failed to delete recipe ' + recipe.name + ' for author ' + loginCredentials.authorId,
            level: 'error',
            tags: {
                feature: 'recipes',
            },
            extra: recipe
        });
    }

    static sendFailedPushNotification(error, loginCredentials) {
        Sentry.captureEvent({
            message: '500 code for push' + error + ' for author ' + loginCredentials.authorId,
            level: 'error',
            tags: {
                feature: 'push',
            }
        });
    }

}