import React from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const DuplicateDeletePopover = ({ id, open, anchorEl, onClose, onDuplicateClick, onDeleteClick }) => {
  const classes = useStyles();
  
  const handleDuplicate = () => {
    onDuplicateClick();
    onClose();
  };

  const handleDelete = () => {
    onDeleteClick();
    onClose();
  };

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    >
      <div className={classes.popupContainer}>
        <Button className={classes.popupButton} onClick={handleDuplicate}>
          Duplicate
        </Button>
        <Button className={classes.alertPopupButton} onClick={handleDelete}>
          Delete
        </Button>
      </div>
    </Popover>
  );
};

const useStyles = makeStyles({
    popupContainer: {
      padding: '5px',
      display: 'flex',
      flexDirection: 'column'
    },
    alertPopupButton: {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 600,
      color: '#D23D45',
      textTransform: 'none'
    },
    popupButton: {
      fontFamily: 'Inter',
      fontSize: '10px',
      fontWeight: 600,
      color: '#575757',
      textTransform: 'none'
    }
  });

export default DuplicateDeletePopover;
