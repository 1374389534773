import Button from '@mui/material/Button';
import { styled } from '@mui/system';

const BlueishButton = styled(Button)({
    width: '100% !important',
    backgroundColor: '#E4ECF7 !important',
    color: '#505780 !important',
    padding: '10px 20px !important',
    borderRadius: '8px !important',
    cursor: 'pointer !important',
    fontSize: '14px !important',
    fontWeight: '600 !important',
    fontFamily: 'Inter !important',
    '&:hover': {
        backgroundColor: '#E4EFFF !important',
    },
});

  export default BlueishButton;