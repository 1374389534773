import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@material-ui/core/styles';

import BlueButton from 'src/Elements/BlueButton';
import createCategoryExampleIcon from 'src/Assets/new/createCategoryExample.png'

const CreateEditCategoryDialog = ({ open, onClose, onValueSubmit, editingCategory }) => {
    const [textValue, setTextValue] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (editingCategory) {
            setTextValue(editingCategory.name || '');
        }
    }, [editingCategory]);

    const handleInputChange = (e) => {
        setTextValue(e.target.value);
        setError('');
    };

    const handleDialogClose = () => {
        onClose();
    };

    const handleDialogSubmit = () => {
        if (textValue.trim().length < 4) {
            setError('Category name must be at least 4 characters.');
        } else {
            onValueSubmit(textValue);
            onClose();
        }
    };

    const classes = useStyles();

    return (
        <Dialog open={open} onClose={handleDialogClose}>
            <DialogContent className={classes.dialogContent}>
                <img
                    src={createCategoryExampleIcon}
                    alt="Create Category Example"
                    className={classes.dialogImage}
                />
                <DialogTitle>
                    <Typography className={classes.title}>
                        {editingCategory ? "What will you change your category name to" : "What will you call your new category?"}
                    </Typography>
                </DialogTitle>
                <TextField
                    fullWidth
                    label="Category Name"
                    variant="outlined"
                    value={textValue}
                    onChange={handleInputChange}
                    error={Boolean(error)}
                    helperText={error}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <BlueButton
                    onClick={handleDialogSubmit}
                >
                    {editingCategory ? "Update" : "Create"}
                </BlueButton>
            </DialogActions>
        </Dialog>
    );
};


const useStyles = makeStyles({
    dialogContent: {
        textAlign: 'center',
        padding: '30px 50px !important',
    },
    dialogImage: {
        width: '205px',
        height: '141px',
    },
    title: {
        textAlign: 'left',
        fontFamily: 'Inter',
        fontWeight: 500,
        fontSize: '14px',
        color: '#425466',
    },
    dialogActions: {
        justifyContent: 'center',
        padding: '10px 50px !important',
    }
});

export default CreateEditCategoryDialog;
