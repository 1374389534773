import React, { useState, useContext } from 'react';
import BlueButton from 'src/Elements/BlueButton';
import { makeStyles } from '@material-ui/core/styles';
import { NetworkProvider } from 'src/NetworkProvider';
import { AuthContext } from 'src/AuthProvider';
import AddFoodCategoryDialog from './AddFoodCategoryDialog';
import Recipes from '../Recipes';
import FoodCategoriesCollection from './FoodCategoriesCollection';
import DeleteConfirmDialog from '../../../../Utilities/Popups/DeleteConfirmDialog';

const StateEnum = {
    MAIN: 0,
    FILTEREDRECIPES: 1,
};

const FoodCategories = ({ foodCategories, setFoodCategories, setLoadingText, recipes, setRecipes, onClose }) => {
    const [state, setState] = useState(StateEnum.MAIN);
    const [editingCategory, setEditingCategory] = useState(null);
    const [openCategory, setOpenCategory] = useState(null);
    const [isCreateEditCategoryOpen, setIsCreateEditCategoryOpen] = useState(null);
    const [activeId, setActiveId] = useState(null);
    const classes = useStyles();
    const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

    const [categoryToDelete, setCategoryToDelete] = useState(null);

    const handleCategoryClick = (category) => {
        setOpenCategory(category);
        setState(StateEnum.FILTEREDRECIPES);
    };


    const deleteCategory = async () => {
        try {
            setLoadingText('Deleting category...');
            await NetworkProvider.delete_food_category(loginCredentials, setLoginCredentials, categoryToDelete.id);
            setFoodCategories((prevCategories) => prevCategories.filter((category) => category.id !== categoryToDelete.id));
            setLoadingText(null);
            setCategoryToDelete(null);
        } catch (error) {
            setLoadingText(null);
            setCategoryToDelete(null);
            window.alert(error);
        }
    }
    

    const handleCategoryEditClick = (category) => {
        setEditingCategory(category);
        setIsCreateEditCategoryOpen(true);
    };

    const handleCategoryReorder = async (updatedCategories) => {
        try {
            await Promise.all(updatedCategories.map(category =>
                NetworkProvider.update_food_category(loginCredentials, setLoginCredentials, category.title, category.id, category.index)
            ));
        } catch (error) {
            window.alert(error);
        }
    }

    const handleClose = () => {
        onClose();
    };

    const createEditCategorySubmit = async (categoryName) => {
        try {
            if (editingCategory) {
                setLoadingText('Updating category...');
                await NetworkProvider.update_food_category(loginCredentials, setLoginCredentials, categoryName, editingCategory.id, editingCategory.index);
                setFoodCategories((prevCategories) =>
                    prevCategories.map((category) => (category.id === editingCategory.id ? { ...category, title: categoryName } : category))
                );
                setEditingCategory(null);
                setLoadingText(null);
            } else {
                setLoadingText('Adding category...');
                const index = foodCategories.length + 1;
                const response = await NetworkProvider.add_food_category(loginCredentials, setLoginCredentials, categoryName, index);
                setFoodCategories((prevCategories) => {
                    const updatedCategories = [...prevCategories, response];
                    return updatedCategories.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                });
                setIsCreateEditCategoryOpen(false);
                setLoadingText(null);
            }
        } catch (error) {
            setLoadingText(null);
            alert(error);
        }
    };

    const CategoriesListContent = () => {
        if (foodCategories.length === 0) {
            return (
                <div className={classes.addCategoryWrapper}>
                    <div className={classes.addCategoryContainer}>
                        {/*<img src={categoriesExampleIcon} alt="Categories Example" className={classes.categoriesExampleIcon2} />*/}
                        <h3 className={classes.exampleTitle}>
                            Lets add your first food category. Food categories contain a list of recipes
                        </h3>
                        <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add category</BlueButton>
                    </div>
                </div>
            );
        } else {
            return (
                <>
                    <h2 className={classes.mainTitle}>Food categories</h2>
                    <header className={classes.header}>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={handleClose}>View all recipes</BlueButton>
                        </div>
                        <div className={classes.headerButton}>
                            <BlueButton onClick={() => setIsCreateEditCategoryOpen(true)}>Add food category</BlueButton>
                        </div>
                    </header>
                    <FoodCategoriesCollection
                        foodCategories={foodCategories}
                        setFoodCategories={setFoodCategories}
                        recipes={recipes}
                        handleCategoryClick={handleCategoryClick}
                        handleCategoryDeleteClick={(category) => setCategoryToDelete(category)}
                        handleCategoryEditClick={handleCategoryEditClick}
                        handleCategoryReorder={handleCategoryReorder}
                        setIsCreateEditCategoryOpen={setIsCreateEditCategoryOpen}
                        handleClose={handleClose}
                        activeId={activeId}
                        setActiveId={setActiveId}
                    />
                </>
            );
        }
    };

    return (
        <>
            {state === StateEnum.MAIN && (
                <>
                    <CategoriesListContent />
                    {isCreateEditCategoryOpen && (
                        <AddFoodCategoryDialog
                            open={isCreateEditCategoryOpen}
                            onClose={() => setIsCreateEditCategoryOpen(false)}
                            title="Dialog Title"
                            onValueSubmit={createEditCategorySubmit}
                            editingCategory={editingCategory}
                        />
                    )}
                </>
            )}
            {state === StateEnum.FILTEREDRECIPES && (
                <Recipes
                    title={`Recipes > ${openCategory.title}`}
                    onBack={() => setState(StateEnum.MAIN)}
                    recipes={recipes}
                    setRecipes={setRecipes}
                    openCategory={openCategory}
                    foodCategories={foodCategories}
                    setLoadingText={setLoadingText}
                />
            )}
            <DeleteConfirmDialog
                open={categoryToDelete != null}
                title={categoryToDelete ? "Are you sure you want to delete " + categoryToDelete.title + " category?" : null}
                subtitle="Deleting a category doesn't delete associated recipes"
                onConfirm={deleteCategory}
                onCancel={() => setCategoryToDelete(null)}
            />
        </>
    );
};

const useStyles = makeStyles({
    mainTitle: {
        fontSize: 29,
        fontFamily: 'Inter',
        fontWeight: 600,
        color: '#000000',
    },
    categoriesTitle: {
        color: '#000000',
        fontSize: 20,
        fontFamily: 'Inter',
        fontWeight: 400,
    },
    categoryList: {
        display: 'flex',
        gap: 20,
        flexWrap: 'wrap',
    },
    header: {
        backgroundColor: 'white',
        padding: 5,
        borderRadius: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: 100,
    },
    headerButton: {
        marginLeft: '5px',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'start',
    },
    categoriesExampleIcon: {
        maxWidth: 200,
    },
    categoriesExampleIcon2: {
        maxWidth: 300,
    },
    exampleContainer: {
        textAlign: 'center',
    },
    exampleTitle: {
        fontSize: 13,
        fontFamily: 'Inter',
        fontWeight: 500,
        marginBottom: 5,
        color: '#736F6F',
    },
    addCategoryWrapper: {
        width: '100%',
        height: '100%',
    },
    addCategoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        width: '300px',
        margin: '0 auto',
    },
});

export default FoodCategories;
