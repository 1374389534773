import { CLOUD_URL } from 'src/contants';

export const video_thumbnail_url_from_file_name = (video_file_name, authorId) => {
  if (video_file_name) {
    let newFileName;

    if (video_file_name.includes('.')) {
      const fileNameWithoutExtension = video_file_name.split('.').slice(0, -1).join('.');
      newFileName = `${fileNameWithoutExtension}.jpeg`;
    } else {
      newFileName = `${video_file_name}.jpeg`;
    }

    return CLOUD_URL + authorId + "/thumbnails/" + newFileName
  } else {
    return null
  }
};

export const validated_email = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  if (emailRegex.test(email)) {
    return email;
  } else {
    return '';
  }
};

export const validated_user_name = (username) => {
  const min_length = 4;
  const max_length = 30;
  const allowed_characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789_';

  if (!(min_length <= username.length && username.length <= max_length)) {
    return '';
  }

  if (username.split('').every((c) => allowed_characters.includes(c))) {
    return username;
  } else {
    return '';
  }
};

export const validated_phone_number = (phoneNumber) => {
  const phoneNumberRegex = /^\+?\d{1,4}([-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9})?$/;

  if (phoneNumberRegex.test(phoneNumber)) {
    return phoneNumber;
  } else {
    return '';
  }
};

export const validated_address = (address) => {
  const min_length = 5;
  const max_length = 200;

  if (min_length <= address.length && address.length <= max_length) {
    return address;
  } else {
    return '';
  }
};

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const image_url_for_image = (image) => {
  if (!image) {
    return null;
  }
  if (image.startsWith('https://') || image.startsWith('http://') || image.startsWith('blob:')) {
    return image;
  } else {
    return `${CLOUD_URL}${image}`;
  }
}

export const getNameFromAuthorData = (authorData) => {
  if (!authorData || !authorData.name) {
    return ''
  }

  const splitName = authorData.name.split(' ');
  if (splitName.length === 0) {
    return ''
  }

  return splitName[0];
}

export const getSurnameFromAuthorData = (authorData) => {
  if (!authorData || !authorData.name) {
    return ''
  }

  const splitName = authorData.name.split(' ');
  if (splitName.length < 2) {
    return '';
  }

  return splitName.slice(1).join(' ');
}


export const createThumbnail = async (file, fileName, onThumbnailReady) => {
  const video = document.createElement('video');
  const url = URL.createObjectURL(file);
  video.src = url;
  let captureFrameCalled = false;

  const captureFrame = () => {
    if (captureFrameCalled) {
      const existingCanvas = document.querySelector('canvas');
      if (existingCanvas) {
        existingCanvas.remove();
      }

      const existingVideo = document.querySelector('video');
      if (existingVideo) {
        existingVideo.remove();
      }
      return;
    }

    captureFrameCalled = true;
    video.removeEventListener('seeked', captureFrame);
    const canvas = document.createElement('canvas');
    const maxDimension = 512;

    // Calculate the scaling factor to maintain aspect ratio
    const scale = Math.min(maxDimension / video.videoWidth, maxDimension / video.videoHeight);

    canvas.width = video.videoWidth * scale;
    canvas.height = video.videoHeight * scale;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    canvas.toBlob(async (blob) => {
      if (blob) {
        const thumbnailFile = new File([blob], fileName + ".jpeg", { type: "image/jpeg" });
        onThumbnailReady(thumbnailFile)

        const existingCanvas = document.querySelector('canvas');
        if (existingCanvas) {
          existingCanvas.remove();
        }

        const existingVideo = document.querySelector('video');
        if (existingVideo) {
          existingVideo.remove();
        }
      }
    }, 'image/jpeg');
  };

  video.addEventListener('canplaythrough', () => {
    video.currentTime = 1;
  });

  video.addEventListener('seeked', captureFrame);
  video.load();
};
