import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CategoryPopover from '../EditDeletePopover/EditDeletePopover';

const EditableCategory = ({ category, onCategoryClick, onDeleteClick, onEditClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const onThreeDotsClick = (event) => {
    event.stopPropagation(); 
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const calculateExercises = (plans) => {
    let totalExercises = 0;
    plans.forEach(plan => {
      plan.weeks.forEach(week => {
        week.days.forEach(day => {
          if (day.exercises) {
            totalExercises += day.exercises.length;
          }
        });
      });
    });
    return totalExercises;
  };


  return (
    <div>
      <div key={category.id} className={classes.categoryBox} onClick={() => onCategoryClick(category)}>
        <h2 className={classes.categoryTitle}>{category.name}</h2>
        <p className={classes.categorySubtitle}>{calculateExercises(category.plans)} exercises</p>
        <div className={classes.dots} onClick={(event) => onThreeDotsClick(event)}>
          ...
        </div>
      </div>
      <CategoryPopover 
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        onEditClick={() => onEditClick(category)}
        onDeleteClick={() => onDeleteClick(category)}
      />
    </div>
  );
};

const useStyles = makeStyles({
  categoryBox: {
    position: 'relative',
    width: 166,
    height: 166,
    border: '1px solid #ACACAC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
    boxSizing: 'border-box',
    cursor: 'pointer',
  },
  categoryTitle: {
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 600,
    marginBottom: 0,
    color: '#000000'
  },
  categorySubtitle: {
    marginBottom: 0,
    fontSize: 12,
    fontFamily: 'Inter',
    fontWeight: 400,
    color: '#737373',
  },
  dots: {
    position: 'absolute',
    bottom: 10,
    right: 0,
    cursor: 'pointer',
    writingMode: 'vertical-lr'
  }
});

export default EditableCategory;
