
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCcVisa, faCcMastercard, faCcAmex } from '@fortawesome/free-brands-svg-icons';
import './CardIcon.css';

const CardIcon = ({ brand }) => {
  const getCardIcon = (brand) => {
    switch (brand) {
      case 'visa':
        return <FontAwesomeIcon className="card-icon" icon={faCcVisa} />;
      case 'mastercard':
        return <FontAwesomeIcon className="card-icon" icon={faCcMastercard} />;
      case 'amex':
        return <FontAwesomeIcon className="card-icon" icon={faCcAmex} />;
      default:
        return null;
    }
  };

  return <div className="card-icon">{getCardIcon(brand)}</div>;
};

export default CardIcon;