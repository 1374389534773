import React, { useContext } from "react";
import { useElements, useStripe, CardElement } from "@stripe/react-stripe-js";
import { NetworkProvider } from '../../NetworkProvider';
import './PaymentsMethodForm.css';
import { AuthContext } from 'src/AuthProvider';
import BlueButton from "src/Elements/BlueButton";


const PaymentMethodForm = ({ onPaymentMethodCreate, setLoadingText }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { loginCredentials, setLoginCredentials } = useContext(AuthContext);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      window.alert(error);
    } else {
      try {
        setLoadingText("adding payment method")
        const data = await NetworkProvider.add_payment_method(loginCredentials, setLoginCredentials, paymentMethod.id);
        onPaymentMethodCreate(paymentMethod);
        setLoadingText(null)
      } catch (error) {
        setLoadingText(null)
        window.alert(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div id="card-element">
        Card Number:
        <CardElement options={{ hidePostalCode: true}} />
      </div>
      <div className="add-card">
        <BlueButton type="submit">Add Card</BlueButton>
      </div>
    </form>
  );
};

export default PaymentMethodForm;