import React, { useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavigationContext from './Components/Navigation/NavigationContext';
import ProtectedRoute from './Components/Navigation/ProtectedRoute';
import { AuthProvider } from './AuthProvider';

import CreateAccount from './Components/Onboarding/CreateAccount/CreateAccount';
import SignIn from './Components/Onboarding/SignIn/SignIn';
import CompanySetup from './Components/Onboarding/CompanySetup/CompanySetup';
import Purchasesuccess from './Components/Onboarding/PurchaseSuccess/PurchaseSuccess';
import Admin from './Components/Admin/Admin';
import ForgotPassword from './Components/Onboarding/ForgotPassword/ForgotPassword';
import ResetPassword from './Components/Onboarding/ResetPassword/ResetPassword';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import * as Sentry from "@sentry/react";

import { 
  CREATE_ACCOUNT_URI, 
  SIGN_IN_URI, 
  FORGOT_PASSWORD_URI,
  COMPANY_SETUP_URI,
  PURCHASE_SUCCESS_URI,
  ADMIN_URI,
  RESET_PASSWORD_URI,
  SENTRY_DSN,
  GOOGLE_CAPTCHA_KEY
} from './contants';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/appscribe\.io/,  /^https:\/\/viralmintlab\.com/],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const [onboardingState, setOnboardingState] = useState(0);

  return (
    <NavigationContext.Provider value={{ onboardingState, setOnboardingState }}>
      <AuthProvider>
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_CAPTCHA_KEY}>
          <Router>
            <Routes>
              <Route path={CREATE_ACCOUNT_URI} element={<ProtectedRoute children={<CreateAccount />} />} />
              <Route path={SIGN_IN_URI} element={<ProtectedRoute children={<SignIn />} />} />
              <Route path={FORGOT_PASSWORD_URI} element={<ProtectedRoute children={<ForgotPassword />} />} />
              <Route path={RESET_PASSWORD_URI} element={<ProtectedRoute children={<ResetPassword />} />} />
              <Route path={COMPANY_SETUP_URI} element={<ProtectedRoute children={<CompanySetup />} />} />
              <Route path={PURCHASE_SUCCESS_URI} element={<ProtectedRoute children={<Purchasesuccess />} />} />
              <Route path={ADMIN_URI} element={<ProtectedRoute children={<Admin />} />} />
            </Routes>
          </Router>
        </GoogleReCaptchaProvider>
      </AuthProvider>
    </NavigationContext.Provider>
  );
  
}

export default App;
